import axios from 'axios'

export const FETCH_FILTERS_PROCESS = 'FETCH_FILTERS_PROCESS'
export const FETCH_FILTERS_SUCCESS = 'FETCH_FILTERS_SUCCESS'
export const FETCH_FILTERS_ERROR = 'FETCH_FILTERS_ERROR'

const config = {
  serverUrl: 'http://localhost:3008',
}

export const getSavedFilters = () => async dispatch => {
  dispatch({
    type: FETCH_FILTERS_PROCESS,
  })
  let res
  try {
    res = await axios.get(`${config.serverUrl}/api/monitor/filters`)
    const data = res.data.savedFilters
    dispatch({
      type: FETCH_FILTERS_SUCCESS,
      payload: {
        data,
      },
    })
  } catch (error) {
    dispatch({
      type: FETCH_FILTERS_ERROR,
      payload: { error: error.response },
    })
  }
  return res.data.savedFilters
}
