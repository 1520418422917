
const getServerUrl = () => {
  console.log('env', process.env)
  console.log('origin', window.location.origin)
  console.log('value', process.env.REACT_APP_SERVER_URL)

  let serverUrl = process.env.REACT_APP_SERVER_URL

  if (serverUrl) return serverUrl

  serverUrl = window.location.origin === 'http://localhost:3006' ? 'http://localhost:3008' : window.location.origin

  console.log('server url', serverUrl)

  return serverUrl
}

const config = {
  keycloak: {
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    url: process.env.REACT_APP_KEYCLOAK_SERVER_URL,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    callbackUrl: process.env.REACT_APP_KEYCLOAK_CALLBACK_URL,
  },
  serverUrl: getServerUrl(),
  logoutCallbackUri: `${window.location.origin}/`,
  standbyCluster: {
    includeStandbyNodes: process.env.REACT_APP_INCLUDE_STANDBY_CLUSTER_NODES
  },
}

console.log('config', config)

export default config
