import React, { useState } from 'react'
import moment from 'moment'
import { SubItem } from './styles'
import { snapshotRequest, urlRequest, startupPrompt, postRequest, getRequest } from './requests'

const getCounter = (counters, typeId) => {
  const counter = counters.filter(c => c.typeId === typeId)
  if (counter && counter.length > 0) {
    return counter[0]
  } else {
    console.log("Couldnt find counters. Other counters:", counters)
    return { value: 'Not found' }
  }
}

const getExchangeMode = (value) => {
  if (value === "true") {
    return "Readonly"
  } else {
    return "Connected"
  }
}

const renderDescribeData = (describeInfo) => {
  return <div>{(describeInfo.split('|').map(line => (<SubItem key={line}>{
    line.toLowerCase().indexOf('time') > -1 ? `${line.split('=')[0]}=${new moment(Number(line.split('=')[1]))}` : line
  }</SubItem>)))}</div>
}

const renderSessions = (sessions) => (sessions.map(s => <SubItem key={s}>{s}</SubItem>))

const renderCounter = (counter) => {
  return (<SubItem key={counter.counterId}>{
    `${counter.label} (${counter.counterId},${counter.typeId}): ${counter.value}`
  } </SubItem>)
}

const renderCounters = (counters, ids = [212, 207, 200, 201, 203, 202, 205, 214, 213, 0, 220,205,223,231,227]) => {
  return <div>{counters
    .filter(c => !ids || ids.indexOf(c.typeId) > -1)
    .map(counter => (renderCounter(counter)))}</div>
}

const renderChecksums = (data) => {
  const renderBalances = (balances) => Object.keys(balances).map(k => <div style={{paddingLeft: "5px" }} key={k}>{k}:{balances[k]}</div>)
  return (<div>{Object.keys(data).map(key => <SubItem key={key}>{key}={typeof data[key] === 'object' ? renderBalances(data[key]) : data[key]}</SubItem>)}</div>)
}

const toggleVisibility = (visibility, setVisibility, name) => {
  setVisibility({ ...visibility, [name]: !visibility[name] })
}

const confirmAndShutdownAeronOnNode = (state) => {
 const confirmed =  window.confirm('Please note if you do this, the applications potentially won\'t connect to new leader until this node is restarted. Also note that the exchange will handover a lot faster.');
 if (confirmed) {
   postRequest(state.memberId, '%2Fcluster%2Fshutdown%2Fnode', { shutdown: false })
  }
 else {
   alert('aborted')
  }
}

const renderDangerous = (state) => {
      return (<div>
        <h3>Dangerous requests</h3>
        <button onClick={() => postRequest(state.memberId, '%2Fcluster%2Fshutdown%2Fnode', { shutdown: true })}>Shutdown node</button>
        <button onClick={() => confirmAndShutdownAeronOnNode(state)}>Shutdown cluster for handover but keep node alive</button>
        <button onClick={() => urlRequest(state.memberId, '%2Fstartup%2Fbroadcast-handover', { overrideSuspended: false })}>Broadcast handover if suspended</button>
        <button onClick={() => urlRequest(state.memberId, '%2Fstartup%2Fbroadcast-handover', { overrideSuspended: true })}>Broadcast handover if not suspended</button>
        {!!state.data.nodeMetaData && !state.data.nodeMetaData.transitioned && renderStandbyClusterMember(state)}
      </div>)
}

const renderStandbyClusterMember = (state) => {
  return (
    <button onClick={() => postRequest(state.memberId, '%2Fcluster%2Fstandby%2Ftransition')}>Transition to Consensus Module</button>
  )
}

const renderLeaderButtons = (state) => {
  return (
    <>
      <h3>Leader requests</h3>
      <div>
        <button onClick={() => snapshotRequest(state.memberId)}>Take Snapshot</button>
        <button onClick={() => urlRequest(state.memberId, '%2Fcluster%2Fsuspend')}>Suspend Cluster</button>
        <button onClick={() => urlRequest(state.memberId, '%2Fcluster%2Fresume')}>Resume Cluster</button>
        <button onClick={() => urlRequest(state.memberId, '%2Fdata%2Forderbook%2Fsaveandverify')}>Save In Memory Orderbook Diff to DB and Verify</button>
      </div>

      <div>
        <h3>Startup prompts</h3>
        <div>
          <label>Handover failure</label> <br/>
          <button onClick={() => startupPrompt(state.memberId, 'HANDOVER_FAILURE', 'RESUME')}>Resume</button>
          <button onClick={() => startupPrompt(state.memberId, 'HANDOVER_FAILURE', 'VERIFY_VIA_DB')}>Verify startup via db</button>
          <button onClick={() => startupPrompt(state.memberId, 'HANDOVER_FAILURE', 'RETRY_LAST_HANDOVER')}>Retry last handover</button>
          <button onClick={() => startupPrompt(state.memberId, 'HANDOVER_FAILURE', 'RETRY_LAST_HANDOVER_IGNORING_LEADERSHIP_TERM_ID')}>Retry last handover ignoring leadership term id</button>
        </div>
        <div>
          <label>Db Failures</label>
          <div>
            <label>Balances</label><br/>
            <button onClick={() => startupPrompt(state.memberId, 'PERSISTED_BALANCES', 'FIX_AND_RETRY')}>Fix And Retry Account Balances</button>
            <button onClick={() => startupPrompt(state.memberId, 'PERSISTED_BALANCES', 'CONTINUE')}>Continue on Account Balances failure</button>
          </div>
          <div>
            <label>Order Books</label><br/>
            <button onClick={() => startupPrompt(state.memberId, 'PERSISTED_ORDER_BOOK_DIFFS', 'FIX_AND_RETRY')}>Fix And Retry Order book diffs</button>
            <button onClick={() => startupPrompt(state.memberId, 'PERSISTED_ORDER_BOOK_DIFFS', 'CONTINUE')}>Continue on Order book diffs failure</button>
          </div>
          <div>
            <label>Misc</label><br/>
            <button onClick={() => startupPrompt(state.memberId, 'TRANSACTION_HISTORY', 'CONTINUE')}>Continue on transaction history</button>
            <button onClick={() => startupPrompt(state.memberId, 'PERSISTED_PRICE_ALERTS', 'FIX_AND_RETRY')}>Fix And Retry Price Alerts</button>
          </div>
        </div>
      </div>
  </>
  )

}

const renderToggle = (name, visibility, setVisibility) => (<div><button onClick={() => toggleVisibility(visibility, setVisibility, name)}>{name}</button></div>)

const renderBackupMember = (state, visibility, setVisibility) => {
  console.log("starting log")

  const result = (<div>
    <h2>Exchange Member: {state.memberId}</h2>
    { <div>Log Position: { getCounter(state.counters, 209).value} </div>}
    <div>Role: {state.role} </div>
    <div>Backup State: { getCounter(state.counters, 208).value }</div>
    <div>Next Query Deadline: { moment(getCounter(state.counters, 210).value).format('DD MMM HH:mm:ss:SSS') }</div>
    <div>Cluster Backup Errors: { getCounter(state.counters, 211).value }</div>
    <div>{visibility.showCounters
      ? <div>{renderToggle('showCounters', visibility, setVisibility)} {state.counters && renderCounters(state.counters, [0])}</div>
      : renderToggle('showCounters', visibility, setVisibility)}
    </div>
    {renderRecordingManagement(state)}
  </div>)

  console.log("render backup done")

  return result
}

const renderActiveMemberDetails = (state, visibility, setVisibility, timerInfo, settings, handover, latestSnapshot) => {
  return (<div>

      <h2 style={state.role === 'LEADER' ? {fontWeight: 'bold', color: 'green'} : {}}>Exchange Member: {state.memberId}</h2>
      {state.logPosition && <div>Log Position: {state.logPosition} </div>}
      <div>Role: {state.role} </div>
      {state.time && <div>Cluster time: {new moment(state.time).format('DD MMM HH:mm:ss:SSS')}</div>}
      <div>Version: {state.data && state.data.version || "N/A"} </div>
      {state.standbyState && <div>Standby State: {state.standbyState} </div>}
      {state.data.nodeMetaData && <div style={{ fontSize:"0.8em" }}>Standby Sub Role: {state.data.nodeMetaData.roleDescription} </div>}
      <div style={{color: getCounter(state.data.counters, 200) && getCounter(state.data.counters, 200).value !== 1 ? 'RED' : 'GREEN' }}>State<span style={{ fontSize: '0.6em' }}>(0=Init,1=Active,2=Suspended,3=Snapshot...)</span>: {state.data.counters && <span>{getCounter(state.data.counters, 200).value}</span>}</div>
      <div style={{color: state.data.readOnlyMode==="true" ? 'RED' : 'GREEN'}}>Mode: {getExchangeMode(state.data.readOnlyMode)}</div>

    {timerInfo && <div>{JSON.stringify(timerInfo)}</div>}
    {settings && <div style={{fontSize:"small"}}>
      {Object.keys(settings).map(k => <div> {k}({settings[k].versionTypeId}):current={settings[k].setVersion},latest={settings[k].latestCodeVersion}</div>)}
    </div>}
    {handover && <div>{JSON.stringify(handover)}</div>}
    {latestSnapshot && <div style={{ fontSize:"0.7em" }}>{latestSnapshot.response.split("\n").map(b => <div>{b}</div>)}</div>}
    {visibility.showChecksum
      ? <div>{renderToggle('showChecksum', visibility, setVisibility)} {state.data.latestChecksum && renderChecksums(state.data.latestChecksum)}</div>
      : renderToggle('showChecksum', visibility, setVisibility)}
    {visibility.showSessions
      ? <div>{renderToggle('showSessions', visibility, setVisibility)} {state.data.sessions && renderSessions(state.data.sessions)}</div>
      : renderToggle('showSessions', visibility, setVisibility)}
    {visibility.showCounters
      ? <div>{renderToggle('showCounters', visibility, setVisibility)} {state.data.counters && renderCounters(state.data.counters)}</div>
      : renderToggle('showCounters', visibility, setVisibility)}
    {visibility.showDescribe
      ? <div>{renderToggle('showDescribe', visibility, setVisibility)} {state.data && renderDescribeData(state.data.describeInfo)}</div>
      : renderToggle('showDescribe', visibility, setVisibility)}
  </div>
  )
}

const getRequestAndSet = async (memberId, url, set) => {
  const result = await getRequest(memberId, url, {})
  set(result)
}

const renderReplicateArchiveRecording = (memberId) => {
  return (<button onClick={() => {
    const srcRecordingId = window.prompt('Please provide srcRecordingId:');
    const dstRecordingId = window.prompt('Please provide dstRecordingId:');
    const srcArchiveRequestChannel = window.prompt('Please provide srcArchiveRequestChannel:');
    postRequest(memberId, '%2Farchive%2Freplicate-recording', { srcRecordingId, dstRecordingId, srcArchiveRequestChannel })
  }}
  >Replace local recording with recording from other exchange</button>)
}

const renderRecordingManagement = (state) => {
  return (<div>
    <h3>Recording Management (Dangerous/Alpha)</h3>
    <button onClick={() => getRequest(state.memberId, '%2Fcluster%2Frecording-log%2F20').then(res => alert(JSON.stringify(res)))}>Describe recording log</button>
    <button onClick={() => getRequest(state.memberId, '%2Fcluster%2Fstandby-recording-log%2F20').then(res => alert(JSON.stringify(res)))}>Describe standby recording log</button>
    <button onClick={() => { let recordingId = prompt("RecordingId"); getRequest(state.memberId, `%2Fcluster%2Flist-recording-info%2F${recordingId}`).then(res => alert(JSON.stringify(res))) }}>List recording info</button>
    <button onClick={() => { let recordingId = prompt("RecordingId"); let position = prompt("position"); postRequest(state.memberId, '%2Farchive%2Fdetach', { "recordingId": recordingId, "newStartPosition": position })} }>Detach segments from cluster recording</button>
    <button onClick={() => postRequest(state.memberId, '%2Farchive%2Fattach', { "recordingId": 0 })}>Attach segments</button>
  </div>)
}

const renderSyncResilienceConfig = (state) => {
  const [selectedResilienceProps, setSelectedResilienceProps] = useState([]);
  return (<div>
    <h3>Sync Resilience Configuration</h3>
    <button onClick={() => getRequest(state.memberId, '%2Fsync%2Fresilience').then(res => alert(JSON.stringify(res, null, 2)))}>Get Current Resilience Config</button>
    <button onClick={() => postRequest(state.memberId, '%2Fsync%2Fresilience', buildSyncResilienceConfig(selectedResilienceProps)).then(res => alert(JSON.stringify(res)))}>Update Resilience Config</button>
    <button onClick={() => postRequest(state.memberId, '%2Fsync%2Fresilience%2Freset').then(res => alert(JSON.stringify(res)))}>Reset Resilience Config</button>
    <label>
      Config Properties:
      <select name="resilienceConfigOptions" multiple={true} value={selectedResilienceProps}
              onChange={e => {
                const options = [...e.target.selectedOptions];
                const values = options.map(option => option.value);
                setSelectedResilienceProps(values);
              }}>
        <option value="">None</option>
        <option value="allowVersionInfoFailure">VersionInfo</option>
        <option value="allowDynamicConfigFailure">DynamicConfig</option>
        <option value="allowCurrenciesFailure">Currencies</option>
        <option value="allowCurrencyPairsFailure">CurrencyPairs</option>
        <option value="allowTradeFeesFailure">TradeFees</option>
        <option value="allowLedgerBalancesFailure">LedgerBalances</option>
        <option value="allowOrderBooksFailure">OrderBooks</option>
        <option value="allowOrderBookSequenceNumberFailure">OrderBookSequenceNumber</option>
        <option value="allowStopOrderFailure">StopOrder</option>
        <option value="allowLoanBookFailure">LoanBook</option>
        <option value="allowMarkPriceFailure">MarkPrice</option>
        <option value="allowTradeFailure">Trade</option>
        <option value="allowAccountStatusesFailure">AccountStatuses</option>
        <option value="allowInterestAuctionSequenceNumberFailure">InterestAuctionSequenceNumber</option>
        <option value="allowInterestAuctionInfoFailure">InterestAuctionInfo</option>
        <option value="allowInterestAuctionConfigFailure">InterestAuctionConfig</option>
        <option value="allowMarginRequirementsFailure">MarginRequirements</option>
        <option value="allowLiquidationSettingsFailure">LiquidationSettings</option>
        <option value="allowTimerInfoFailure">TimerInfo</option>
        <option value="allowSlippageProtectionSettingsFailure">SlippageProtectionSettings</option>
        <option value="allowIndexPricesFailure">IndexPrices</option>
        <option value="allowOpenFuturesPositionsFailure">OpenFuturesPositions</option>
        <option value="allowDatabaseSequenceNumberFailure">DatabaseSequenceNumber</option>
        <option value="allowTypedSequenceNumberFailure">TypedSequenceNumber</option>
        <option value="allowCustomMarginFractionPerPairFailure">CustomMarginFractionPerPair</option>
        <option value="allowConditionalOrderFailure">ConditionalOrders</option>
      </select>
    </label>
    <p>Selected for update: {selectedResilienceProps.join(', ')}</p>
  </div>)
}

const buildSyncResilienceConfig = (data) => {
  if (data.length === 0) return
  let model = {}
  for (const dataKey of data) {
    if (dataKey === 'allowVersionInfoFailure') { model = {...model, allowVersionInfoFailure: true  } }
    if (dataKey === 'allowDynamicConfigFailure') { model = {...model, allowDynamicConfigFailure: true } }
    if (dataKey === 'allowCurrenciesFailure') { model = {...model, allowCurrenciesFailure: true } }
    if (dataKey === 'allowCurrencyPairsFailure') { model = {...model, allowCurrencyPairsFailure: true } }
    if (dataKey === 'allowTradeFeesFailure') { model = {...model, allowTradeFeesFailure: true } }
    if (dataKey === 'allowLedgerBalancesFailure') { model = {...model, allowLedgerBalancesFailure: true } }
    if (dataKey === 'allowOrderBooksFailure') { model = {...model, allowOrderBooksFailure: true } }
    if (dataKey === 'allowOrderBookSequenceNumberFailure') { model = {...model, allowOrderBookSequenceNumberFailure: true } }
    if (dataKey === 'allowStopOrderFailure') { model = {...model, allowStopOrderFailure: true } }
    if (dataKey === 'allowLoanBookFailure') { model = {...model, allowLoanBookFailure: true } }
    if (dataKey === 'allowMarkPriceFailure') { model = {...model, allowMarkPriceFailure: true } }
    if (dataKey === 'allowTradeFailure') { model = {...model, allowTradeFailure: true } }
    if (dataKey === 'allowAccountStatusesFailure') { model = {...model, allowAccountStatusesFailure: true } }
    if (dataKey === 'allowInterestAuctionSequenceNumberFailure') { model = {...model, allowInterestAuctionSequenceNumberFailure: true } }
    if (dataKey === 'allowInterestAuctionInfoFailure') { model = {...model, allowInterestAuctionInfoFailure: true } }
    if (dataKey === 'allowInterestAuctionConfigFailure') { model = {...model, allowInterestAuctionConfigFailure: true } }
    if (dataKey === 'allowMarginRequirementsFailure') { model = {...model, allowMarginRequirementsFailure: true } }
    if (dataKey === 'allowLiquidationSettingsFailure') { model = {...model, allowLiquidationSettingsFailure: true } }
    if (dataKey === 'allowTimerInfoFailure') { model = {...model, allowTimerInfoFailure: true } }
    if (dataKey === 'allowSlippageProtectionSettingsFailure') { model = {...model, allowSlippageProtectionSettingsFailure: true } }
    if (dataKey === 'allowIndexPricesFailure') { model = {...model, allowIndexPricesFailure: true } }
    if (dataKey === 'allowOpenFuturesPositionsFailure') { model = {...model, allowOpenFuturesPositionsFailure: true } }
    if (dataKey === 'allowDatabaseSequenceNumberFailure') { model = {...model, allowDatabaseSequenceNumberFailure: true } }
    if (dataKey === 'allowTypedSequenceNumberFailure') { model = {...model, allowTypedSequenceNumberFailure: true } }
    if (dataKey === 'allowCustomMarginFractionPerPairFailure') { model = {...model, allowCustomMarginFractionPerPairFailure: true } }
    if (dataKey === 'allowConditionalOrderFailure') { model = {...model, allowConditionalOrderFailure: true } }
  }
  return model
}

export default function Member(props) {
  const { state, visibility, setVisibility } = props
  const [timerInfo, setTimerInfo] = useState(null)
  const [settings, setSettings] = useState(null)
  const [handover, setHandover] = useState(null)
  const [latestSnapshot, setLatestSnapshot] = useState(null)

  return state ? (
    <div key={state.memberId}>

      { console.log("1") && <div>1</div>}

      {(state.role !== 'BACKUP') &&
        renderActiveMemberDetails(state, visibility, setVisibility, timerInfo, settings, handover, latestSnapshot)
      }

      { console.log("2") && <div>1</div>}

      {state.role === 'BACKUP' &&
        renderBackupMember(state, visibility, setVisibility)
      }

      { console.log("3") && <div>1</div>}

      { state.role !== 'BACKUP' &&
        <div>
          <button onClick={() => getRequestAndSet(state.memberId, '%2Ftimer_info', setTimerInfo)}>Timer info</button>
          <button onClick={() => getRequestAndSet(state.memberId, '%2Fsettings', setSettings)}>Settings</button>
          <button onClick={() => getRequestAndSet(state.memberId, '%2Fhandover', setHandover)}>Handover</button>
          <button onClick={() => getRequestAndSet(state.memberId, `%2Fcluster%2Fdescribe-latest-snapshot`, setLatestSnapshot)}>Describe latest snapshot</button>
          <button onClick={() => getRequestAndSet(state.memberId, `%2Fcluster%2Fdescribe-latest-standby-snapshot`, setLatestSnapshot)}>Describe latest standby snapshot</button>
          <button onClick={() => postRequest(state.memberId, '%2Fsettings%2Floglevel', { levelName: 'DEBUG' })}>Log Debug</button>
          <button onClick={() => postRequest(state.memberId, '%2Fsettings%2Floglevel', { levelName: 'INFO' })}>Log Info</button>
          <button onClick={() => postRequest(state.memberId, '%2Fsettings%2Flogevents%2Ftoggle', {  })}>Toggle Log Events</button>
          <button onClick={() => postRequest(state.memberId, '%2Fcluster%2Fpatch-pending-service-message-in-snapshot')}>Patch pending service messages in latest snapshot</button>
          <button onClick={() => urlRequest(state.memberId, '%2Fcluster%2Fsnapshot%2Finvalidatelatest')}>Invalidate latest snapshot</button>
          <button onClick={() => getRequest(state.memberId, '%2Fhandover%2Fsubscription%2Factive')}>is handover listener active</button>
          <button onClick={() => postRequest(state.memberId, '%2Fhandover%2Fsubscription%2Frestart-listener')}>Restart handover listener</button>

          {state.role !== 'BACKUP' && renderReplicateArchiveRecording(state.memberId)}

          {state.role === 'LEADER' && renderLeaderButtons(state)}

          {state.role !== 'BACKUP' && renderDangerous(state)}

          {state.role !== 'BACKUP' && renderRecordingManagement(state)}

          {state.role !== 'BACKUP' && renderSyncResilienceConfig(state)}
        </div>
      }

      { console.log("4") && <div>1</div>}

    </div>
  ) : <div>No State</div>
}
