import React from 'react'
import LoadingBlock from 'components/molecules/LoadingBlock/index'
import * as moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Table, TableHeader, TableBody, TableCell, TableRow,
} from './styles'

const Logs = props => {
  const { data, status } = props.podLogs
  return (
    <Table>
      <TableHeader>
        <TableCell flex={0.14}>Time Stamp</TableCell>
        <TableCell flex={1}>Text Payload</TableCell>
      </TableHeader>
      {status === 'loading' && <LoadingBlock />}
      {status === 'done' && (
        <InfiniteScroll
          dataLength={data ? data.length : 0}
          useWindow={false}
          height={600}
        >
          <TableBody fontSize={'80%'}>
            {data && data.map(log => (
              <TableRow key={log.insertId}>
                <TableCell flex={0.14}>
                  {moment
                    .unix(log.timestamp.seconds)
                    .format('YYYY-MMM-DD HH:mm:ss')}
                </TableCell>
                <TableCell flex={1}>{log.textPayload}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </InfiniteScroll>
      )}
    </Table>
  )
}

export default Logs
