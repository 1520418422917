import React, { Component } from 'react'

class Callback extends Component {
  render() {
    return (
      <p>Authenticating...</p>
    )
  }
}

export default Callback
