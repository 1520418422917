import React from 'react'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

const renderCompareGrid = (props) => {
  if (props.rowData == null || props.rowData.length === 0) return null

  return (
    <div className="ag-theme-alpine" style={ { fontSize: '14px', height: '600px', width: '1000px'} }>
      <AgGridReact
        columnDefs={props.columnDefs}
        rowData={props.rowData}>
      </AgGridReact>
    </div>
  )
}

export const renderFullGrid = (props) => {
  if (props.rowData == null || props.rowData.length === 0) return null

  return (
    <div className="ag-theme-alpine" style={ { fontSize: '12px', height: '600px'} }>
      <AgGridReact
        columnDefs={props.columnDefs}
        rowData={props.rowData}>
      </AgGridReact>
    </div>
  )
}

export default renderCompareGrid
