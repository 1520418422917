import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import getInstance from 'helpers/axios'
import Select from 'react-select'
import {
  Container, SelectStyle,
} from './styles'

import CompareGrid, { renderFullGrid as FullGrid } from './CompareGrid'

const restartMarketSummaries = async () => {
  try {
    await getInstance().post(`/api/bittrex/${'%2Frestart%2Fmarketsummaries'}`)
    toast('Successfully restarted market summaries', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
  } catch (error) {
    toast.error(`Could not restart market summaries ${error}`)
  }
}

const getOrderHistory = async (marketSymbol, setState, startTime, endTime) => {
  try {
    const result = await getInstance().get(`/api/bittrex/order-history?marketSymbol=${marketSymbol}&startTime=${startTime}&endTime=${endTime}`)
    setState(result.data)
  } catch (error) {
    toast.error(`Could not get order history for ${marketSymbol}, ${error.response.data.error}`)
  }
}

const getOpenWithdrawals = async (currency, setState) => {
  try {
    const result = await getInstance().get(`/api/bittrex/open-withdrawals?symbol=${currency}`)
    setState(result.data)
  } catch (error) {
    toast.error(`Could not get open withdrawals for ${currency}, ${error.response.data.error}`)
  }
}

const getDeposits = async (currency, setState) => {
  try {
    const result = await getInstance().get(`/api/bittrex/deposits?symbol=${currency}`)
    setState(result.data)
  } catch (error) {
    toast.error(`Could not get open withdrawals for ${currency}, ${error.response.data.error}`)
  }
}

const getOrderBookComparison = async (currencyPair, setState) => {
  try {
    const result = await getInstance().get(`/api/orderbookcompare/${currencyPair}`)
    setState(result.data)
  } catch (error) {
    toast.error(`Could not restart ${currencyPair}, ${error.response.data.error}`)
  }
}

const restartOrderBook = async (currencyPair) => {
  try {
    await getInstance().post(`/api/bittrex/%2Frestart%2Forderbook%2F${currencyPair.replace('-','')}`)
    toast(`Successfully restarted ${currencyPair} `, { type: toast.TYPE.SUCCESS, autoClose: 1500 })
  } catch (error) {
    toast.error(`Could not restart ${currencyPair}, ${error}`)
  }
}

const restartAllOrderBooks = async () => {
  try {
    await getInstance().post(`/api/bittrex/%2Frestart%2Fallorderbooks`)
    toast('Successfully restarted all order books', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
  } catch (error) {
    toast.error(`Could not restart all order books ${error}`)
  }
}

const setLogLevel = async (data) => {
  try {
    await getInstance().post(`/api/bittrex/%2Fsettings%2Floglevel`, data)
    toast('Log level set', { type: toast.TYPE.SUCCESS, autoClose: 1500 })
  } catch (error) {
    toast.error(`Could not set log level ${error}`)
  }
}

const getBittrexDate = (date) => {
      //2019-01-02T16:23:45Z
      return `${date.getFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDay().toString().padStart(2, '0')}T${date.getUTCHours().toString().padStart(2, '0')}:${date.getUTCMinutes().toString().padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')}Z`
}

const Bittrex = () => {
  const [openWithdrawals, setOpenWithdrawals] = useState([])
  const [deposits, setDeposits] = useState({ })
  const [orderHistory, setOrderHistory] = useState([])
  const [startTime, setStartTime] = useState(getBittrexDate(new Date(new Date().getTime() - 24*60*60*1000)))
  const [endTime, setEndTime] = useState(getBittrexDate(new Date()))
  const [selectedCurrency, setCurrency] = useState('')
  const [selectedCurrencyPair, setCurrencyPair] = useState('')
  const [currencies, setCurrencies] = useState('')
  const [logLevel, setLogLevelToDisplay] = useState('')
  const [optionsValue, setOptiosValues] = useState('')
  const [orderbookCompare, setOrderBookCompare] = useState([])
  const logLevels = [{ value: 'DEBUG', label: 'DEBUG' }, { value: 'INFO', label: 'INFO' }]

  const handleCurrencyChange = (e) => setCurrency(e.value)
  const handleCurrencyPairChange = (e) => setCurrencyPair(e.value)
  const handleLogLevel = (e) => setLogLevelToDisplay(e.value)

  const getCurrencyPairs = async () => {
    try {
      const res = await getInstance().get('/api/currency/pairs')
      const { data } = res
      const availableCurrencyPairs = data.filter(currencyPair => {
        return currencyPair.symbol.indexOf('ZAR') === -1
      }).map(currencyPair => (
        {
          value: currencyPair.shortName.replace('/','-'),
          label: currencyPair.shortName.replace('/','-'),
        }))
      setOptiosValues(availableCurrencyPairs)
    } catch (error) {
      return error
    }
  }

const loadCurrencies = async () => {
  const res = await getInstance().get(`/api/currencies`)
  const currencies = res.data.map(c => ({ value: c.shortName, label: c.shortName }))
  setCurrencies(currencies)
}


  useEffect(() => {
    getCurrencyPairs()
    loadCurrencies()
  }, [])

  return (<Container>
    <SelectStyle style={{ paddingTop: '20px' }}>
      <Select options={currencies} placeholder={'Select currency '}
        onChange={handleCurrencyChange} />
      <button style={{ width: '60%', fontWeight: '60' }} disabled={selectedCurrency === ''} onClick={() => getOpenWithdrawals(selectedCurrency, setOpenWithdrawals)} >Get Open Withdrawals</button><br />
      <button style={{ width: '60%', fontWeight: '60' }} disabled={selectedCurrency === ''} onClick={() => getDeposits(selectedCurrency, setDeposits)} >Get Deposits</button><br />


  </SelectStyle>
    <SelectStyle style={{ paddingTop: '20px' }}>
      <Select options={optionsValue} placeholder={'Select currency pair'}
        onChange={handleCurrencyPairChange} />
      <button style={{ width: '60%', fontWeight: '60' }} disabled={selectedCurrencyPair === ''} onClick={() => restartOrderBook(selectedCurrencyPair)} >Restart Order Book</button><br />
      <button style={{ width: '60%', fontWeight: '60' }} disabled={selectedCurrencyPair === ''} onClick={() => getOrderBookComparison(selectedCurrencyPair, setOrderBookCompare)} >Compare Order Book</button><br />
      <div style={{display: 'grid', gridAutoColumns: "auto" }}>
        <button style={{ width: '60%', fontWeight: '60' }} disabled={selectedCurrencyPair === ''} onClick={() => getOrderHistory(selectedCurrencyPair, setOrderHistory, startTime, endTime)} >Get Order History</button><br />
        <input type='text' value={startTime} onChange={(e) => setStartTime(e.target.value) }></input>
        <input type='text' value={endTime} onChange={(e) => setEndTime(e.target.value) }></input>
      </div>
  </SelectStyle>
    <SelectStyle style={{ paddingTop: '20px' }} >
      <Select options={logLevels} placeholder={'Select log level'} onChange={handleLogLevel} />
      <button style={{ width: '60%', fontWeight: '60' }} disabled={logLevel === ''} onClick={() => setLogLevel({ levelName: logLevel })} >Set Log Level</button><br />
    </SelectStyle>
    <SelectStyle>
    </SelectStyle>
    <div style={{ paddingTop: '20px' }}>
      <button style={{ fontWeight: '60' }} onClick={() => restartAllOrderBooks()}>Restart All Order Books</button>
      <button style={{ margin: '5px', fontWeight: '60' }} onClick={() => restartMarketSummaries()}>Restart market summaries</button>
    </div>
    <div style={{ display: "flex" }}>
      <div>
        <h3>Bids</h3>

    <CompareGrid columnDefs={[
      { headerName: "VALR Price", field: "valrPrice" },
      { headerName: "Bittrex Price", field: "bittrexPrice" },
      { headerName: "VALR Quantity", field: "valrQuantity" },
      { headerName: "Bittrex Quantity", field: "bittrexQuantity" },
      { priceEquals: "Price Equals", field: "priceEquals" },
      ]}
     rowData={orderbookCompare.filter(o => o.side === "bid")}
    /></div>

      <div><h3>Asks</h3>
    <CompareGrid columnDefs={[
      { headerName: "VALR Price", field: "valrPrice" },
      { headerName: "Bittrex Price", field: "bittrexPrice" },
      { headerName: "VALR Quantity", field: "valrQuantity" },
      { headerName: "Bittrex Quantity", field: "bittrexQuantity" },
      { priceEquals: "Price Equals", field: "priceEquals" },
    ]}
     rowData={ orderbookCompare.filter(o => o.side === "ask") }
    /></div>

    </div>
    <div>
    <div><h3>Open Withdrawals</h3></div>
    { openWithdrawals.length > 0 && <div>{ openWithdrawals.map(w => (<div>{ JSON.stringify(w) }</div> )) }</div>}
    <div><h3>Open Deposits</h3></div>
    { deposits && deposits.openDeposits && <div>{ deposits.openDeposits.map(d => (<div>{ JSON.stringify(d)} </div>)) }</div>}
    <div><h3>Closed Deposits</h3></div>
    { deposits && deposits.closedDeposits && <div>{ deposits.closedDeposits.map(d => (<div>{ JSON.stringify(d)} </div>)) }</div>}
    <div><h3>Order History</h3></div>
      <div><h3>Asks</h3>
    <FullGrid columnDefs={[
      { headerName: "clientOrderId", field: "clientOrderId" }, 
      { headerName: "closedAt", field: "closedAt" }, 
      { headerName: "commission", field: "commission" }, 
      { headerName: "createdAt", field: "createdAt" }, 
      { headerName: "direction", field: "direction" }, 
      { headerName: "fillQuantity", field: "fillQuantity" }, 
      { headerName: "id", field: "id" }, 
      { headerName: "marketSymbol", field: "marketSymbol" }, 
      { headerName: "proceeds", field: "proceeds" }, 
      { headerName: "quantity", field: "quantity" }, 
      { headerName: "status", field: "status" }, 
      { headerName: "timeInForce", field: "timeInForce" }, 
      { headerName: "type", field: "type" }, 
      { headerName: "updatedAt", field: "updatedAt" }, 



     ]}
     rowData={ orderHistory }
    /></div>
</div>
  </Container>)
}

export default Bittrex
