import React, { useState } from 'react'

const li = (text) => {
  return <li style={{ display: "list-item" }}>{text}</li>
}

export default function LiveReconClient() {
  const [show, setShow] = useState({ states: [] })

  if (!show) {
    return (<button onClick={() => setShow(!show)}>Show deploy steps</button>)
  }

  return (
    <div>
      <h3>Deploy steps</h3><button onClick={() => setShow(!show)}>Hide</button>
      <h4>Checklist</h4>
      <ol>
        {li('Ensure that live recon is up and running')}
        {li('Have you taken a snapshot?')}
        {li('Deploy new image to followers (using deploy script) and confirm that checksums are matching after letting a couple of orders through (request via live recon)')}
        {li('Press: Shutdown cluster for handover but keep node alive on leader (which will suspend cluster and broadcast state to followers and live recon but keep node alive)')}
        {li('Once there is a new leader, we can continue with deploy script to set old leader to new image')}
      </ol>
    </div >
  )
}
