import styled from 'styled-components'

export const Container = styled.div`
`

export const ExchangeStates = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 10px;
`

export const SubItem = styled.div`
  margin-left: 20px;
  font-size: 0.8em;
  border-bottom: solid;
  border-width: thin;
`

export const Field = styled.div`
  padding: 2px;
  border-top: 1px solid black;
  border-left: 1px solid black;
`