import keycloak from 'keycloak-js'
import store, {history} from '../store'
import config from '../config'
import {clearRefreshRedirect} from '../redux/routing/actions';
import getInstance from "../helpers/axios";

const jsonwebtoken = require('jsonwebtoken')

const accessTokenKey = 'insight_access_token'
const refreshTokenKey = 'insight_refresh_token'
const idTokenKey = 'insight_id_token'
const accessTokenExpiresInKey = 'insight_token_expires_at'

class Keycloak {

  loginRedirectUri = ''
  logoutCallbackUri = ''

  constructor(onSession) {
    this.onSession = onSession
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.init = this.init.bind(this)
    this.refreshToken = this.refreshToken.bind(this)
    this.updateToken = this.updateToken.bind(this)
    this.keycloak = new keycloak(config.keycloak)
    this.keycloak.onTokenExpired = this.refreshToken.bind(this)
    this.keycloak.onAuthRefreshSuccess = this.updateToken.bind(this)
  }

  async init() {
    try {
      this.loginRedirectUri = config.keycloak.callbackUrl
      this.logoutCallbackUri = config.logoutCallbackUri
      // TODO just need to test something else before enabling this
      //await this.getDynamicConfigurationOrElseFallbackToStaticDefaults()

      console.log('Init for keycloak.')
      const authenticated = await this.keycloak.init({
        onLoad: 'check-sso',
        promiseType: 'native',
        redirectUri: this.loginRedirectUri,
      })

      console.log(authenticated ? 'authenticated' : 'not authenticated')

      if (!authenticated || !this.onSession) {
        return
      }

      this.onSession({token: this.keycloak.token})
    } catch (e) {
      console.error(e)
    }
  }

  async getDynamicConfigurationOrElseFallbackToStaticDefaults() {
    try {
      const dynamicConfigResponse = await getInstance().get("/app/config/")
      console.log(`Fetched dynamic server configuration: ${JSON.stringify(dynamicConfigResponse.data)}`)

      if (!!dynamicConfigResponse && !!dynamicConfigResponse.data && Object.keys(dynamicConfigResponse.data).length !== 0) {
        console.log(`Using dynamically defined auth config`)
        this.loginRedirectUri = dynamicConfigResponse.data.callbackUrl
        this.logoutCallbackUri = dynamicConfigResponse.data.logoutCallbackUri
      } else {
        console.log(`Falling back to using statically defined auth config`)
        this.loginRedirectUri = config.keycloak.callbackUrl
        this.logoutCallbackUri = config.logoutCallbackUri
      }
    } catch (e) {
      console.log(`Error fetching dynamic configuration. Falling back to static defaults`)
      this.loginRedirectUri = config.keycloak.callbackUrl
      this.logoutCallbackUri = config.logoutCallbackUri
    }
  }

  handleAuthentication() {
    console.log('auth:', 'handling authentication')
    if (this.hasExpired()) {
      console.log('auth:', 'logging out due to expired token')
      this.logout()
    }

    if (!this.keycloak.authenticated) {
      console.log('auth:', 'not authenticated. attempting to log in')
      this.login()
    }

    console.log('auth:', 'user is logged in')

    this.setTokenValues();
    history.replace('/')
  }

  login() {
    this.keycloak.login()
  }

  logout() {
    console.log('auth: removing tokens...')
    localStorage.removeItem(accessTokenKey)
    localStorage.removeItem(idTokenKey)
    localStorage.removeItem(refreshTokenKey)
    localStorage.removeItem(accessTokenExpiresInKey)
    console.log('auth: clearing local state...')
    store.dispatch(clearRefreshRedirect())
    console.log('auth: initiating logout...')
    this.keycloak.logout({redirectUri: this.logoutCallbackUri})
  }

  isAuthenticated() {
    return this.keycloak.authenticated
  }

  hasExpired() {
    const accessToken = localStorage.getItem(accessTokenKey)
    if (!accessToken) {
      console.log('auth:', 'no insight access token detected')
      return true
    }

    const decodedString = jsonwebtoken.decode(accessToken)
    const expiresAtString = decodedString.exp * 1000
    const expiresAt = JSON.parse(expiresAtString)

    const isExpired = new Date().getTime() >= expiresAt
    if (isExpired) {
      console.log('auth:', 'token has expired')
      alert('Your Session has expired')
    }
    return isExpired
  }

  refreshToken() {
    console.log('auth: refreshing token')
    this.keycloak
      .updateToken(10)
      .then(refreshed => {
        if (refreshed) {
          console.log('auth: successfully refreshed token')
        } else {
          console.log('auth: not refreshing token, still valid')
        }
      })
      .catch(e => console.error('a refresh token error has occurred: ', e))
  }

  updateToken() {
    this.setTokenValues()
  }

  setTokenValues() {
    localStorage.setItem(idTokenKey, this.keycloak.idToken)
    localStorage.setItem(refreshTokenKey, this.keycloak.refreshToken)
    localStorage.setItem(accessTokenKey, this.keycloak.token)
    localStorage.setItem(accessTokenExpiresInKey, String(this.keycloak.tokenParsed.exp * 1000))
    if (this.onSession) {
      this.onSession({token: this.keycloak.token})
    }
  }

  decodeToken() {
    const accessToken = localStorage.getItem(accessTokenKey)
    return jsonwebtoken.decode(accessToken, {})
  }
}

const authService = new Keycloak(({token}) => {
  localStorage.setItem(accessTokenKey, token)
})

export default authService
