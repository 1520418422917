import React, {useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {Container} from './styles'
import getInstance from '../../helpers/axios'


const LiveRecon = () => {
  const [config, setConfig] = useState({ key: '', value:'', type: '' })
  const [allConfig, setAllConfig] = useState()

  const putLiveReconRequest = async (url, data) => {
    try {
      await getInstance().put(`/api/liverecon/${url}`, data)
      getLiveReconRequest('%2Fconfig', setAllConfig)
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  const getLiveReconRequest = async (url, set) => {
    try {
      const result = await getInstance().get(`/api/liverecon/${url}`)
      set(result.data)
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }
  useEffect(() => {
    getLiveReconRequest('%2Fconfig', setAllConfig)
  },[])

  return (<Container>
    <div>
      <h3>Live Recon</h3>
      { allConfig && Object.keys(allConfig).map(k => <div>{k}:{JSON.stringify(allConfig[k])}</div> )}
      <div>
        <label>Key</label>
        <input type='text' value={config.key} onChange={(e) => setConfig({ ...config, key: e.target.value }) }></input>
      </div>
      <div>
        <label>Value</label>
        <input type='text' value={config.value} onChange={(e) => setConfig({ ...config, value: e.target.value }) }></input>
      </div>
      <div>
        <label>Type</label>
        <select onChange={(e) => setConfig({ ...config, type: e.target.value })}>
          <option value=""></option>
          <option value="LONG">LONG</option>
          <option value="BIG_DECIMAL">BIG_DECIMAL</option>
          <option value="MAP_STRING_BIG_DECIMAL">MAP_STRING_BIG_DECIMAL</option>
        </select>
        <input type='text' value={config.type} onChange={(e) => setConfig({ ...config, type: e.target.value }) }></input>
      </div>

      <button onClick={() => { putLiveReconRequest('%2Fconfig', config) }}>Submit</button>
    </div>
  </Container>)
}

export default LiveRecon
