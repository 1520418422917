import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getLogs, saveNewFilter } from 'redux/podLogs/actions'
import { getSavedFilters } from 'redux/filters/actions'
import { Container, TitleContainer } from './styles'
import Logs from './logs-display'
import FilterForm from './filters-form'

class PodLogs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputText: '',
      onSave: false,
      dropdownOption: {},
      filterToSend: '',
      filterLabel: '',
    }
  }

  async componentDidMount() {
    const { getSavedFilters, getLogs } = this.props
    const filters = await getSavedFilters()
    var filter = JSON.parse(filters[0])
    this.setState({
      dropdownOption: filter,
      inputText: filter.value,
      filterToSend: filter.value,
    })
    await getLogs(filter.value)
  }

  handleTextInput = e => {
    var value = e.target.value
    var filter = `${this.state.dropdownOption.value} \n ${value}`
    this.setState({ inputText: value, filterToSend: filter })
  }

  handleLabelInput = e => {
    var label = e.target.value
    this.setState({ filterLabel: label })
  }

  handleDropdown = (e, options) => {
    var value = e.target.value
    const option = options.find(option => JSON.parse(option).value === value)
    var filter = `${this.state.dropdownOption.value} \n ${this.state.inputText}`
    this.setState({ dropdownOption: JSON.parse(option), filterToSend: filter })
  }

  handleSaveBtn = () => {
    var save = this.state.onSave
    if (save === true) {
      this.setState({ onSave: false })
    } else {
      this.setState({ onSave: true })
    }
  }

  saveFilter = async () => {
    const { filterToSend, filterLabel } = this.state
    var filterObj = {
      label: filterLabel,
      value: filterToSend,
    }
    await this.props.saveNewFilter(filterObj)
    await this.handleSaveBtn()
  }

  render() {
    const { logs, filterOptions } = this.props
    const handlers = {
      handleTextInput: this.handleTextInput,
      handleLabelInput: this.handleLabelInput,
      handleDropdown: this.handleDropdown,
      handleSaveBtn: this.handleSaveBtn,
    }
    return (
      <Fragment>
        <FilterForm
          filter={this.state.filterToSend}
          handlers={handlers}
          saveFilter={this.saveFilter}
          getLogs={this.props.getLogs}
          onSave={this.state.onSave}
          options={filterOptions}
        />
        <Container>
          <TitleContainer>Pod Logs</TitleContainer>
          <Logs podLogs={logs} />
        </Container>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getLogs, saveNewFilter, getSavedFilters }, dispatch)
const mapStateToProps = state => ({
  logs: state.podLogs,
  filterOptions: state.filterOptions,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PodLogs)
