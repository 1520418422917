import React, { useState } from 'react'
import {getSettingsRequest, postSettingsRequest} from './requests'
import { toast } from 'react-toastify'

export default function Settings(props) {
  const [liquidationSettings, setLiquidationSettings] = useState(null)
  const [readonly, setReadOnly] = useState(null)
  const [exchangeReadOnlyFromPrimary, setExchangeReadOnlyUsingPrimary] = useState(null)
  const [exchangeConfig, setExchangeConfig] = useState(null)

  return (
    <div>
      <h3>Settings and State</h3>
      <div>Liquidation Settings</div>
      <div>
        <button onClick={() => getRequestAndSet('liquidation', setLiquidationSettings)}>
          Fetch
        </button>
        <button onClick={() => toggleLiquidation(true)}>
          Enable Liquidation Runner
        </button>
        <button onClick={() => toggleLiquidation(false)}>
          Disable Liquidation Runner
        </button>
      </div>
      <div>{JSON.stringify(liquidationSettings)} </div>
      <div>---</div>
      <h3>Readonly Configurations Using Primary</h3>
      <b>Application Configured Read only status</b>
      <div>
        <div>
          <button onClick={() => getRequestAndSet('readonly', setReadOnly)}>
            Fetch
          </button>
          <button onClick={() => toggleReadOnly(true)}>
            Enable Application ReadOnly
          </button>
          <button onClick={() => toggleReadOnly(false)}>
            Disable Application ReadOnly
          </button>
        </div>
        <div>{JSON.stringify(readonly)} </div>
      </div>
      <br/>
      <b>Exchange Configured Read only Configs Values</b>
      <div>
        <button onClick={() => getRequestAndSet('exchange-read-only-config', setExchangeReadOnlyUsingPrimary)}>
          Fetch
        </button>
        <div>Configs: {JSON.stringify(exchangeReadOnlyFromPrimary)}</div>
      </div>
      <br/>
      <b>Exchange Configured Read only status</b>
      <div>
        <button onClick={() => toggleExchangeReadOnlyOnAdmin(true)}>
          Enable Exchange ReadOnly
        </button>
        <button onClick={() => toggleExchangeReadOnlyOnAdmin(false)}>
          Disable Exchange ReadOnly
        </button>
      </div>
      <br/>
      <b>Exchange Configured GoIntoReadOnlyModeOnResume</b>
      <div>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeOnAdmin(true)}>
          Enable Exchange GoIntoReadOnlyModeOnResume
        </button>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeOnAdmin(false)}>
          Disable Exchange GoIntoReadOnlyModeOnResume
        </button>
      </div>
      <br/>

      <div>---</div>
      <div>Exchange Config</div>
      <div>
        <div>
          <button onClick={() => getRequestAndSet('config', setExchangeConfig)}>
            Fetch
          </button>
          <button onClick={() => toggleCrossAccountTradingDisabled(false)}>
            Enable Cross Account Trading
          </button>
          <button onClick={() => toggleCrossAccountTradingDisabled(true)}>
            Disable Cross Account Trading
          </button>
        </div>
      </div>
      <div>{JSON.stringify(exchangeConfig)} </div>
      <div>---</div>
    </div>
  )
}

const toggleReadOnly = async enabled => {
  const result = await postSettingsRequest('readonly', {enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeReadOnlyOnAdmin = async enabled => {
  const result = await postSettingsRequest('exchange-readonly', {value: enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeGoIntoReadOnlyModeOnResumeOnAdmin = async enabled => {
  const config = 'GO_INTO_READONLY_MODE_ON_RESUME'
  const result = await postSettingsRequest('config/booleanconfig', {config, enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleCrossAccountTradingDisabled = async enabled => {
  const config = 'CROSS_ACCOUNT_TRADING_DISABLED'
  const result = await postSettingsRequest('config/booleanconfig', {config, enabled})
  if (!result.error) toast.success('Success. Please fetch again')
}

const toggleLiquidation = async enabled => {
  const result = await postSettingsRequest('liquidation', { enabled })
  if (!result.error) toast.success('Success. Please fetch again')
}

const getRequestAndSet = async (url, set) => {
  set(null)
  const result = await getSettingsRequest(url)
  set(result)
}
