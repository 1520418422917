import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Exchange from "containers/Exchange/index";
import LiveRecon from "containers/LiveRecon/index";
import Balances from "containers/Balances/index";
import OrderBooks from "containers/OrderBook/index";
import Bittrex from 'containers/Bittrex/index'
import AeronStat from 'containers/AeronStat/index'
import Futures from 'containers/Futures/index'
import FuturesBorrows from 'containers/FuturesBorrows/index'
import Config from 'containers/Config/index'
import '../../App.css'

export default function Home() {
  return (
    <Router>
      <div>
        <ul >
          <li className='li'> <Link to="/exchange">Exchange</Link> </li>
          <li className='li'> <Link to="/liveRecon">Live Recon</Link> </li>
          <li className='li'><Link to="/balances">Balances</Link> </li>
          <li className='li'><Link to="/bittrex">Bittrex</Link> </li>
          <li className='li'><Link to="/orderBooks">Order Books</Link> </li>
          <li className='li'><Link to="/aeronstat">[Aeron Stat (Please don't click)]</Link> </li>
          <li className='li'><Link to="/futures">Futures</Link></li>
          <li className='li'><Link to="/futuresBorrows">&nbsp;Futures Borrows</Link></li>
          <li className='li'><Link to="/config">&nbsp;Config</Link></li>
        </ul>
        <hr />
        <Switch>
          <Route exact path="/exchange">
            <Exchange />
          </Route>
          <Route exact path="/liveRecon">
            <LiveRecon />
          </Route>
          <Route path="/balances">
            <Balances />
          </Route>
          <Route path="/orderBooks">
            <OrderBooks />
          </Route>
          <Route path="/bittrex">
            <Bittrex/>
          </Route>
          <Route path="/aeronstat">
            <AeronStat/>
          </Route>
          <Route path="/futures">
            <Futures/>
          </Route>
          <Route path="/futuresBorrows">
            <FuturesBorrows/>
          </Route>
          <Route path="/config">
            <Config/>
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
