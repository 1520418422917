import axios from 'axios'
import config from '../config'

const getInstance = () => {
  console.log('server url', config.serverUrl)
  return axios.create({
    baseURL: `${config.serverUrl}`,
    headers: {
      common: {
        Authorization: `Bearer ${localStorage.getItem('insight_access_token')}`,
      },
    },
  })
}

export default getInstance
