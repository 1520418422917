import React from 'react'
import styled, { keyframes } from 'styled-components'
import {
  PANEL_BLUE,
  BLACK,
  GRAY_0,
  GRAY_1,
  WHITE,
  GREEN,
  GRAY_3,
  BLUE,
  DEFAULT_THEME,
} from 'theme/colors'
import { BASE } from 'theme/base-units'

export const Title = styled.h1`
  margin: 0;
  font-size: ${3 * BASE}px;
  font-weight: 100;
  text-transform: uppercase;
  flex: 0 0 auto;
`

export const TitleContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${PANEL_BLUE};
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${BASE}px 0;
  color: ${PANEL_BLUE};
  margin-bottom: ${BASE}px;
  margin-left: ${BASE}px;
`
export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ noMargin = false }) => (noMargin ? 0 : `${BASE * 2}px`)};
  width: 100%;
`
export const TableHeader = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ colorTheme = DEFAULT_THEME }) =>
    colorTheme.color.headerBg};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  margin-bottom: 2px;
`
export const TableBody = styled.div`
  font-size: ${({ fontSize = BASE }) => fontSize};
`

export const TableCell = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: ${({ flex = '0 0 auto' }) => flex};
  padding-right: ${({ columnRender = false }) =>
    columnRender ? 0 : `${BASE * 2}px`};
  width: ${({ width = '100%' }) => width};
  max-width: ${({ maxWidth = 'auto' }) => maxWidth};
  cursor: inherit;
  font-weight: ${({ bold = false }) => (bold ? '600' : '400')};
  color: ${({ color }) => color};
  justify-content: ${({ align = 'left' }) =>
    align === 'left' ? 'flex-start' : 'flex-end'};
  text-align: ${({ align = 'left' }) => (align === 'left' ? 'left' : 'right')};
  &:last-child {
    padding-right: 0;
  }
  cursor: inherit;
`
export const blinkOnceEven = keyframes`
  0% {
      background-color: ${GREEN};
  }
  100% {
     background-color: #eaf0f2;
  }
`

export const blinkOnceOdd = keyframes`
  0% {
      background-color: ${GREEN};
  }
  100% {
      background-color: #eaf0f2;
  }
`

const blinkOnce = ({ index = 0 }) => keyframes`
  0% {
      background-color: ${GREEN};
  }
  100% {
      background-color: ${index % 2 === 0 ? '#dce7ea' : '#eaf0f2'};
  }
`

export const TableRow = styled.div`
  display: flex;
  width: ${({ width = '100%' }) => width};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  color: ${BLACK};
  background-color: ${({
    colorTheme = DEFAULT_THEME,
    index = 0,
    active = false,
  }) =>
    active
      ? `${colorTheme.color.hover} !important`
      : index % 2 === 0
        ? colorTheme.color.even
        : colorTheme.color.odd};
  margin-bottom: ${({ expanded = false, imported = false }) =>
    expanded && !imported ? 0 : '2px'};
  margin-top: ${({ expanded = false }) => (expanded ? `${BASE * 2 - 2}px` : 0)};
  cursor: pointer;
  &:nth-child(2n) {
    background-color: #dce7ea;
    animation: ${({ isNewItem = false }) =>
      isNewItem && `${blinkOnceEven} 1s linear 0s 1`};
  }
  &:hover {
    background-color: ${({ colorTheme = DEFAULT_THEME }) =>
      colorTheme.color.hover};
  }
  animation: ${({ isNewItem = false, index = 0 }) =>
    isNewItem && `${blinkOnce(index)} 1s linear 0s 1`};
`

export const TableRowExpanded = styled.div`
  background-color: ${WHITE};
  padding: ${BASE * 3}px;
  margin-bottom: ${BASE * 2}px;
  border: 1px solid #d9e7ec;
  border-top: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
`

export const ExpandingContainer = styled.div`
  margin-bottom: ${({ expanded = false, imported = false }) =>
    expanded && !imported ? 0 : '2px'};
  margin-top: ${({ expanded = false }) => (expanded ? `${BASE * 2 - 2}px` : 0)};
`
export const TablePlaceholder = styled.div`
  width: 100%;
  padding: ${BASE * 2}px 0;
  background-color: ${GRAY_0};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${BASE * 3}px;
  background-color: ${GRAY_0};
  margin: ${BASE * 2}px 0;
  border: 1px solid ${GRAY_1};
`

export const Select = styled.select`
  width: ${({ width = '100%' }) => width};
  height: 35px;
  background: ${GRAY_0};
  color: gray;
  font-size: 14px;
  border: 2px solid ${GRAY_1};
  margin-right: 15px;
`
export const Option = styled.option`
  color: black;
  background: white;
  display: flex;
  white-space: pre;
  min-height: 20px;
  padding: 0px 2px 1px;
`

export const SaveIcon = styled.i`
  margin-right: 16px;
  color: blue;
  font-size: 30px;
`
