import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css' // shouldn't need to import but funny with build tool
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from '../../components/molecules/PrivateRouite'
import auth from '../../services/Auth'
import Exchange from '../Exchange'
import AeronStat from '../AeronStat'
import OrderBooks from '../OrderBook'
import Home from '../Home'
import Balances from '../Balances'
import Callback from '../Callback'
import Login from '../Login'
import PodLogs from '../PodLogs'
import Bittrex from '../Bittrex'

const App = () => (
  <>
    <ToastContainer position="top-right" autoClose={5000} />
    <Switch>
      <Route
        path="/callback"
        render={routeProps => {
          auth.handleAuthentication(routeProps)
          return <Callback {...routeProps} auth={auth} />
        }}
      />
      <Route path="/login" component={Login} />
      <PrivateRoute path="/logs" auth={auth} component={PodLogs} />
      <PrivateRoute path="/orderbooks" auth={auth} component={OrderBooks} />
      <PrivateRoute path="/balances" auth={auth} component={Balances} />
      <PrivateRoute path="/exchange" auth={auth} component={Exchange} />
      <PrivateRoute path="/bittrex" auth={auth} component={Bittrex} />
      <PrivateRoute path="/aeronstat" auth={auth} component={AeronStat} />
      <PrivateRoute path="/" auth={auth} component={Home} />
    </Switch>
  </>
)

export default App
