import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import {
  Container, Table, TableBody,
  TableRow, TableCell, TableHeader,
} from './styles'
import getInstance from 'helpers/axios'
const { default: BigNumber } = require('bignumber.js')

const getPositions = async (currencyPair, setPositions) => {
    const result = await getInstance().get(`/api/positions/${currencyPair}`)
    console.log('positions', result)
    setPositions(result.data)
}

const Positions = () => {
  const [positions, setPositions] = useState([])
  const [currencyPair, setCurrencyPair] = useState('BTCUSDCPERP')
  const [optionsValue, setOptionsValues] = useState('')

  const getCurrencyPairs = async () => {
    try {
      const res = await getInstance().get('/api/currency/pairs')
      const { data } = res
      const availableCurrencyPairs = data.filter(currencyPair => {
        return currencyPair.currencyPairType === "FUTURE" && currencyPair.active
      }).map(currencyPair => (
        {
          value: currencyPair.symbol,
          label: currencyPair.symbol,
        }))
      setOptionsValues(availableCurrencyPairs)
    } catch (error) {
      return error
    }
  }

  useEffect(() => {
    getCurrencyPairs()
  }, [])

  return (<Container>
    <div>
      <div>
        <label>Currency Pair:</label>
        <Select options={optionsValue} placeholder={'Select currency pair'} onChange={(e) => setCurrencyPair(e.value)} />
        <button onClick={() => getPositions(currencyPair, setPositions)}>Get Positions</button>
      </div>
      <div>
        <h3>Positions</h3>
        <div>
          SUM quantity: {positions.reduce((a,r) => r.side === "buy" ? a.plus(new BigNumber(r.quantity)) : a.minus(new BigNumber(r.quantity)), new BigNumber(0)).toString()}&nbsp;
          unrealised Pnl: {positions.reduce((a,r) => a.plus(new BigNumber(r.unrealisedPnl)), new BigNumber(0)).toString()}&nbsp;
          Buy quantity: {positions.reduce((a,r) => { return r.side === "buy" ? a.plus(new BigNumber(r.quantity)) : a;  }, new BigNumber(0)).toString()}&nbsp;
          Sell quantity: {positions.reduce((a,r) => r.side === "sell" ? a.plus(new BigNumber(r.quantity)) : a, new BigNumber(0)).toString()}&nbsp;
          Buy unrealised pnl: {positions.reduce((a,r) => { return r.side === "buy" ? a.plus(new BigNumber(r.quantity)) : a;  }, new BigNumber(0)).toString()}&nbsp;
          Sell unrealised pnl: {positions.reduce((a,r) => r.side === "sell" ? a.plus(new BigNumber(r.quantity)) : a, new BigNumber(0)).toString()}&nbsp;
        </div>
        <Table>
          <TableHeader>
            <TableCell flex={0.2}> pair </TableCell>
            <TableCell flex={0.2}> Side </TableCell>
            <TableCell flex={0.3}> Account Id </TableCell>
            <TableCell flex={0.4}> Quantity </TableCell>
            <TableCell flex={0.4}> realisedPnl </TableCell>
            <TableCell flex={0.4}> averageEntryPrice </TableCell>
            <TableCell flex={0.4}> sessionAverageEntryPrice </TableCell>
            <TableCell flex={0.4}> totalSessionEntryQuantity </TableCell>
            <TableCell flex={0.4}> Total Session Value </TableCell>
            <TableCell flex={0.4}> unrealised pnl </TableCell>
            <TableCell flex={0.6}> updated at </TableCell>
            <TableCell flex={0.3}> Position Id </TableCell>
          </TableHeader>
          <TableBody>
            {positions && positions.map(p => { return (
              <TableRow>
                <TableCell flex={0.2} > {p.pair} </TableCell>
                <TableCell flex={0.2} > {p.side} </TableCell>
                <TableCell flex={0.3} > {p.accountId} </TableCell>
                <TableCell flex={0.4} > {p.quantity} </TableCell>
                <TableCell flex={0.4} > {p.realisedPnl } </TableCell>
                <TableCell flex={0.4} > {p.averageEntryPrice} </TableCell>
                <TableCell flex={0.4} > {p.sessionAverageEntryPrice} </TableCell>
                <TableCell flex={0.4} > {p.totalSessionEntryQuantity } </TableCell>
                <TableCell flex={0.4} > {p.totalSessionValue } </TableCell>
                <TableCell flex={0.4} > {p.unrealisedPnl } </TableCell>
                <TableCell flex={0.6} > {new Date(p.updatedAt).toISOString() } </TableCell>
                <TableCell flex={0.3} > {p.id} </TableCell>
              </TableRow>
            )
            })}
          </TableBody>
        </Table>

      </div>

    </div>
  </Container>)
}

export default Positions
