import React from 'react'
import { BASE } from 'theme/base-units'
import { SimpleContainer } from 'components/atoms/Generic'

const IconLoading = ({ size = BASE * 2, color }) => (
  <SimpleContainer
    fontSize={size}
    color={color}
    marginRight={`${BASE}px`}
    spinning={1}
    display={'flex'}
  >
    <i className="fas fa-circle-notch" />
  </SimpleContainer>
)
export default IconLoading
