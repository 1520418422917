import {
  FETCH_POD_LOGS_PROCESS,
  FETCH_POD_LOGS_SUCCESS,
  FETCH_POD_LOGS_ERROR,
} from './actions'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POD_LOGS_PROCESS: {
      return { ...state, status: 'loading', data: null }
    }

    case FETCH_POD_LOGS_ERROR: {
      const { error } = action.payload
      return { ...state, status: 'error', error }
    }

    case FETCH_POD_LOGS_SUCCESS: {
      const { data } = action.payload
      return { ...state, status: 'done', data }
    }

    default:
      return state
  }
}
