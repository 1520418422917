import React, { useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import getInstance from 'helpers/axios'
import {
  Container, ExchangeStates, Table, TableBody,
  TableRow, TableCell, TableHeader,
} from './styles'
const BigNumber = require('bignumber.js')

const members = [
  { id: 0 },
  { id: 1 },
  { id: 2 },
]

const getAllExchangeStates = async (current, set) => {
  const states = []
  const borrows = []
  for (const m of members) {
    const state = await exchangeStateRequest(m.id)
    const borrow = await exchangeBorrowBalancesRequest(m.id)
    states.push(state)
    borrows.push(borrow)
  }
  set({ ...current, states, borrows })
}

const sumUpTotalCurrency = (data) => {
  const totalZar = []
  const totalEth = []
  const totalBtc = []
  const totalXrp = []
  const totalAda = []
  const totalGno = []
  const totalBat = []
  const totalSlr = []
  const totalBch = []
  const totalLink = []

  const balances = Object.values(data).map((value) => value.balances).flat()
  balances.filter(currencyData => {
    const { currency } = currencyData
    const availableCurrency = currency
    if (availableCurrency === 'ZAR') {
      totalZar.push(currencyData.total)
    } else if (availableCurrency === 'BTC') {
      totalBtc.push(currencyData.total)
    } else if (availableCurrency === 'ETH') {
      totalEth.push(currencyData.total)
    } else if (availableCurrency === 'XRP') {
      totalXrp.push(currencyData.total)
    } else if (availableCurrency === 'ADA') {
      totalAda.push(currencyData.total)
    } else if (availableCurrency === 'GNO') {
      totalGno.push(currencyData.total)
    } else if (availableCurrency === 'BAT') {
      totalBat.push(currencyData.total)
    } else if (availableCurrency === 'SLR') {
      totalSlr.push(currencyData.total)
    } else if (availableCurrency === 'BCH') {
      totalBch.push(currencyData.total)
    } else if (availableCurrency === 'LINK') {
      totalLink.push(currencyData.total)
    }
  })

  const sum = (value => value.reduce((acc, val) => acc.plus(val), BigNumber(0)).toString(10))

  const addedZar = sum(totalZar)
  const addedBtc = sum(totalBtc)
  const addedEth = sum(totalEth)
  const addedXrp = sum(totalXrp)
  const addedAda = sum(totalAda)
  const addedGno = sum(totalGno)
  const addedBat = sum(totalBat)
  const addedSlr = sum(totalSlr)
  const addedBch = sum(totalBch)
  const addedLink = sum(totalBch)
  return (
    <div>
      <p>Available Rand: {addedZar}</p>
      <p>Available Eth: {addedEth}</p>
      <p>Available Btc: {addedBtc}</p>
      <p>Available Xrp: {addedXrp}</p>
      <p>Available Ada: {addedAda}</p>
      <p>Available Gno: {addedGno}</p>
      <p>Available Bat: {addedBat}</p>
      <p>Available Slr: {addedSlr}</p>
      <p>Available Bch: {addedBch}</p>
      <p>Available Link: {addedLink}</p>
    </div>
  )
}

const exchangeBorrowBalancesRequest = async (memberId) => {
  try {
    const result = await getInstance().get(`/api/exchange/${memberId}/${'%2Fdata%2Fbalances%2Fborrow-totals'}`)
    return { ...result.data, memberId }
  } catch (e) {
    toast.error(`Could not get exchange member status ${memberId}`)
  }
}

const exchangeStateRequest = async (memberId) => {
  try {
    const result = await getInstance().get(`/api/exchange/${memberId}/${'%2Fdata%2Fbalances'}`)
    const accounts = Object.values(result.data.data)
    const role = result.data.role

    let total = BigNumber(0)

    const mapped = accounts.map(accountBalances => {
      const balances = accountBalances.balances.map(ab => {

        const item = {
          currency: ab.currency.shortName, 
          available: BigNumber(ab._available), 
          reserved: BigNumber(ab._reserved), 
          borrowedAmount: BigNumber(ab._borrowedAmount), 
          lendReserved: BigNumber(ab._lendReserved), 
          withdrawReserved: BigNumber(ab._withdrawReserved), 
          updatedAt: ab.updatedAt, 
          borrowCollateralReserved: BigNumber(ab._borrowCollateralReserved),
      }
      item.total = item.available.plus(item.reserved).plus(item.lendReserved).plus(item.borrowCollateralReserved).minus(item.borrowedAmount).plus(item.withdrawReserved)
      total = total.plus(item.total)
      return item
    })

      return {
        accountId: accountBalances.accountId,
        balances
      }
    })

    return { data: mapped, memberId, role, total }
  } catch (e) {
    console.error(e)
    toast.error(`Could not get exchange member status ${memberId}`)
  }
}

const getExchangeState = async (memberId, current, set) => {
  const data = await exchangeStateRequest(memberId)
  const borrow = await exchangeBorrowBalancesRequest(memberId)
  const newStates = [...current.states]
  const newBorrows = [...current.borrows]
  newStates[memberId] = data
  newBorrows[memberId] = borrow
  set({ ...current, states: newStates, borrows: newBorrows })
}

const Balances = () => {
  const [exchangeState, setExchangeState] = useState({ states: [], borrows: [] })
  const [checked, setChecked] = useState(false)
  const [showTotals, setShowTotals] = useState(true)
  const [showIndividuals, setShowIndividuals] = useState(false)

  const isDifferentAmount = (rowId, index, total, memberId) => {
    const { states } = exchangeState
    const otherExchange = states.filter(exchange => {
      if (exchange !== undefined) {
        return exchange.memberId !== memberId
      }
    })
    let truthValue = 0
    otherExchange.forEach(exchangeData => {
      const { data } = exchangeData
      console.log(data)
      const rowArray = data[rowId]
      console.log(rowArray)
      const rowInfo = rowArray[index]

      if (rowInfo.total !== total) {
        return truthValue += 1
      }
    })
    return truthValue
  }

  const mismatchesRow = (rowId, index, total, memberId) => {
    const { states } = exchangeState
    const otherExchange = states.filter(exchange => {
      if (exchange !== undefined) {
        return exchange.memberId !== memberId
      }
    })
    const mismatching = []
    otherExchange.map(exchangeData => {
      const { data } = exchangeData
      const rowArray = data[rowId]
      const rowInfo = rowArray[index]
      if (rowInfo.total !== total) {
        mismatching.push(rowInfo)
      }
    })
    return mismatching
  }

  const showAllCurrencyData = (data) => {
    const allData = Object.entries(data.data)
    return allData.map((item) => {
      const res = item[1]
      const arrayToReturn = res.balances
      return arrayToReturn.map((newData, i) => {
        const {
          currency, total, available, reserved, borrowedAmount, lendReserved, withdrawReserved, updatedAt, borrowCollateralReserved,
        } = newData
        return (
          <div>
            <TableRow key={res.accountId + ':' + currency} /*style={{ color: isDifferentAmount(res.accountId, i, total, data.memberId) === 0 ? '#000000' : 'red' }}*/>
              <TableCell flex={0.2} > {res.accountId} </TableCell>
              <TableCell flex={0.7} > {total.toString(10)} </TableCell>
              <TableCell flex={0.4} > {currency} </TableCell>
              <TableCell flex={0.5} > {available.toString(10)} </TableCell>
              <TableCell flex={0.5} > {reserved.toString(10)} </TableCell>
              <TableCell flex={0.5} > {lendReserved.toString(10)} </TableCell>
              <TableCell flex={0.5} > {withdrawReserved.toString(10)} </TableCell>
              <TableCell flex={0.5} > {borrowedAmount.toString(10)} </TableCell>
              <TableCell flex={0.5} > {borrowCollateralReserved.toString(10)} </TableCell>
              <TableCell flex={0.8} > {moment(updatedAt).format('YYYY-MMM-DD HH:mm')} </TableCell>
            </TableRow>
          </div>
        )
      })
    })
  }

  const showMismatches = (data) => {
    const allData = Object.entries(data.data)
    return allData.map((res) => {
      const flattenedArray = res.flat()
      const arrayToReturn = flattenedArray.slice(1)
      return arrayToReturn.map((newData, i) => {
        const {
          total, currency, available, reserved, updatedAt,
        } = newData
        const mismatching = mismatchesRow(flattenedArray[0], i, total, data.memberId)
        if (mismatching.length > 0) {
          return (
            <div>
              <TableRow style={{ color: 'red' }}>
                <TableCell flex={0.2} > {flattenedArray[0]} </TableCell>
                <TableCell flex={0.7} > {total} </TableCell>
                <TableCell flex={0.4} > {currency} </TableCell>
                <TableCell flex={0.5} > {available} </TableCell>
                <TableCell flex={0.5} > {reserved} </TableCell>
                <TableCell flex={0.8} > {moment(updatedAt).format('YYYY-MMM-DD HH:mm')} </TableCell>
              </TableRow>
            </div>
          )
        }
      })
    })
  }

  const handleChange = () => {
    setChecked(!checked)
  }

  return (<Container>
    <div>
      <div>
        <button onClick={() => getAllExchangeStates(exchangeState, setExchangeState)}>Get all</button>
        {members.map(m => (<button key={m.id} onClick={() => getExchangeState(m.id, exchangeState, setExchangeState)}>Get {m.id}</button>))}
      </div>
      <label>
        <input
          onClick={handleChange}
          type="checkbox"
          checked={checked}
          disabled={true}
          /* Broken disabled={exchangeState.states.length === 0}*/
        />
        show only mismatches (Disabled, Broken)
      </label>
      <ExchangeStates>
        {exchangeState.states.map(state => state ? (
          <div key={state.memberId}>
            <h2 style={state.role === 'LEADER' ? { fontWeight: 'bold', color: 'green' } : {}}>Exchange Member: {state.memberId} {
            }</h2>
            <div>Role: {state.role} </div>
            {
            <div><h3>Totals (<button onClick={() => setShowTotals(!showTotals)}>Toggle</button>)</h3>
            {showTotals && sumUpTotalCurrency(state.data)}
            </div>
            }
            <div><h3>Individuals (<button onClick={() => setShowIndividuals(!showIndividuals)}>Toggle</button>)</h3></div>
            {showIndividuals && <Table>
              <TableHeader>
                <TableCell flex={0.2}> Id </TableCell>
                <TableCell flex={0.7}> Total </TableCell>
                <TableCell flex={0.4}> Currency </TableCell>
                <TableCell flex={0.5}> Available </TableCell>
                <TableCell flex={0.5}> Reserved </TableCell>
                <TableCell flex={0.5}> Lend Reserved </TableCell>
                <TableCell flex={0.5}> Withdraw Reserved </TableCell>
                <TableCell flex={0.5}> Borrowed </TableCell>
                <TableCell flex={0.5}> Collateral Reserved </TableCell>
                <TableCell flex={0.8}>Updated At </TableCell>
              </TableHeader>
              <TableBody>
                {checked ? showMismatches(state) :
                  showAllCurrencyData(state)
                }
              </TableBody>
            </Table>
            }

          </div>
        ) : <div>No State</div>)}
      </ExchangeStates>
    </div>

  </Container>)
}

export default Balances
