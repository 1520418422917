export const RED = '#e42013'
export const TRANSPARENT_PINK = 'rgba(228, 32, 19, 0.1)'
export const ORANGE = '#ffbb3a'
export const GREEN = '#7CB246'
export const LIGHT_GREEN = '#e3e9dd'
export const LIGHT_BLUE = '#36b3eb'
export const BLUE = '#4569eb'
export const DARK_BLUE = '#3550b2'
export const VIOLET = '#6846c3'
export const WHITE = '#FFF'
export const GRAY_0 = '#F9F9F9'
export const GRAY_1 = '#dee1e3'
export const GRAY_2 = '#B9BBBD'
export const GRAY_3 = '#6e6e6e'
export const BLUE_GRAY = '#e4ebef'
export const BLACK = '#444444'

export const PAGINATION_ACTIVE = BLUE
export const PAGINATION_NORMAL = GRAY_2

export const WARNING_COLOR = ORANGE
export const ERROR_COLOR = RED
export const INPUT_LABEL_COLOR = GRAY_3
export const TABLE_HEADER = GRAY_2
export const BORDER_COLOR = GRAY_2
export const LIGHT_GREY = GRAY_1
export const LIGHTEST_GREY = GRAY_0
export const DARK_GREY = GRAY_3
export const DROPDOWN_ARROW_BG_COLOR = GRAY_1
export const PANEL_BLUE = BLUE
export const TIMELINE_COLOR = GRAY_1

export const DEFAULT_THEME = {
  color: {
    even: BLUE_GRAY,
    odd: GRAY_2,
    text: PANEL_BLUE,
    hover: '#c4deed',
    headerBg: '#bfc9d4',
  },
}

export const ENDPOINT_THEME = {
  color: {
    even: LIGHT_GREEN,
    odd: LIGHT_GREEN,
    hover: LIGHT_GREEN,
    text: GREEN,
    headerBg: '#bbbfb6',
  },
}

export const NEGATIVE_RED_COLOR_PAIR = {
  positive: BLACK,
  negative: RED,
}

export const RED_COLOR_PAIR = {
  positive: RED,
  negative: GRAY_2,
}

export const GREEN_COLOR_PAIR = {
  positive: GREEN,
  negative: GRAY_2,
}

export const BLACK_COLOR_PAIR = {
  positive: BLACK,
  negative: GRAY_2,
}
