import React, { useState, useEffect } from 'react'

import { getExchangeState, members } from '../Exchange/requests'

import { Container, ExchangeStates } from './styles'
import Member from './Member'

const Exchange = () => {
  const [exchangeState, setExchangeState] = useState({ states: [] })
  const [visibility, setVisibility] = useState({ showCounters: false, showDescribe: false, showSessions: false, showChecksum: false })
  const [leader, setLeader] = useState(null)
  const [frequency, setFrequency] = useState(200)

  useEffect(() => {
    const interval = setInterval(() => { if(leader) getExchangeState(leader, exchangeState, setExchangeState) }, frequency)
    return () => clearInterval(interval)
  })

  return (<Container>
    <div>
      <div>
        <label>Member </label><input type="input" value={leader} onChange={(e) => { setLeader(e.target.value); setExchangeState({ states: [] })}} />
        <label>Frequency </label><input type="input" value={frequency} onChange={(e) => { setFrequency(Number(e.target.value)); }} />
      </div>
      <ExchangeStates>
        {exchangeState.states.filter(state => {
          if (state) { return true } else { return false }
        }).map(state => {
          return (
            state.error
              ? <div>{JSON.stringify(state.error)}</div>
              : <Member key={state.memberId} state={state} visibility={visibility} setVisibility={setVisibility} exchangeState={exchangeState} setExchangeState={setExchangeState} />
          )
        })}
      </ExchangeStates>
    </div>
  </Container>)
}

export default Exchange
