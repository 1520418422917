import styled from 'styled-components'
import { BASE } from 'theme/base-units'
import { GRAY_0 } from 'theme/colors'

export const Container = styled.div`
  width: 100%;
  padding: ${({ padding = BASE * 2 }) => `${padding}px 0`};
  background-color: ${({ bgColor = GRAY_0 }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
`
