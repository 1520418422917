import React, { useState } from 'react';

import { getAllExchangeStates, getExchangeState, members } from './requests'

import { Container, ExchangeStates } from './styles'
import Member from './Member'
import LiveReconClient from './LiveReconClient'
import Settings from './Settings';
import DeploySteps from './DeploySteps'
import Glossary from './Glossary'

const Exchange = () => {
  const [exchangeState, setExchangeState] = useState({ states: [] })
  const [visibility, setVisibility] = useState({ showCounters: false, showDescribe: false, showSessions: false, showChecksum: false })

  return (<Container>
    <div>
      <div>
        <button onClick={() => getAllExchangeStates(exchangeState, setExchangeState)}>Get all</button>
        {members.map(m => (<button key={m.id} onClick={() => getExchangeState(m.id, exchangeState, setExchangeState)}>Get {m.id}</button>))}
      </div>
      <ExchangeStates>
        {exchangeState.states.filter(state => {
          if(state) {return true} else {return false}
        }).map(state => {
          return (
            state.error
              ? <div>{JSON.stringify(state.error)}</div>
              : <Member key={state.memberId} state={state} visibility={visibility} setVisibility={setVisibility} exchangeState={exchangeState} setExchangeState={setExchangeState} />
          )
        })}
      </ExchangeStates>
      <Settings/>
      <LiveReconClient/>
      <div style={{display: "flex" }}>
      <Glossary/>
      <DeploySteps/>
      </div>
    </div>

  </Container>)
}

export default Exchange
