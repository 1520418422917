import React, { useEffect, useState } from 'react'
import {
  Container, Table, TableBody,
  TableRow, TableCell, TableHeader,
} from './styles'
import getInstance from 'helpers/axios'
const { default: BigNumber } = require('bignumber.js')

const getInfo = async (setInfo) => {
  const result = await getInstance().get(`/api/liverecon/%2Faccounts-with-collateral-conversion-needed`)
  setInfo(result.data)
}


const FuturesBorrows = () => {
  const [data, setData] = useState([])

  return (<Container>
    <div>
      <div>
        <button onClick={() => getInfo(setData)}>Get</button>
      </div>
      <div>
        <h3>Futures Borrows</h3>
        <div>
        </div>
        <Table>
          <TableHeader>
            <TableCell flex={0.3}> Account Id </TableCell>
            <TableCell flex={0.4}> Settlement Currency </TableCell>
            <TableCell flex={0.4}> negativeUnrealisedPnl </TableCell>
            <TableCell flex={0.4}> amountNeeded </TableCell>
            <TableCell flex={0.4}> already Converted </TableCell>
          </TableHeader>
          <TableBody>
            {data && data.map(p => { return (
              <TableRow>
                <TableCell flex={0.3} > {p.accountId} </TableCell>
                <TableCell flex={0.4} > {p.settlementCurrency} </TableCell>
                <TableCell flex={0.4} > {p.negativeUnrealisedPnl } </TableCell>
                <TableCell flex={0.4} > {p.amountNeeded} </TableCell>
                <TableCell flex={0.4} > {p.alreadyConverted} </TableCell>
              </TableRow>
            )
            })}
          </TableBody>
        </Table>

      </div>

    </div>
  </Container>)
}

export default FuturesBorrows 
