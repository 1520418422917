import React, { useState } from 'react'
import moment from 'moment'
import { SubItem, Field } from './styles'
import { groupBy } from 'underscore'

const renderCounter = (counter) => {
  return (<SubItem key={counter.counterId}>{
    `${counter.label} (${counter.counterId},${counter.typeId}): ${counter.value}`
  } </SubItem>)
}

const mapChannelFromCounter = (counter) => {
  const split = counter.label.replace(' (sampled)','').split(' ')

  return {
    counterId: counter.counterId,
    typeId: counter.typeId,
    type: split[0],
    session: split[2],
    channel: split[4],
    value: counter.value,
    streamId: split[1],
  }
}


const renderChannelDetails = (item) => {
  return (
    <>
    <Field>{item.counterId}</Field>
    <Field>{item.typeId}</Field>
    <Field>{item.type}</Field>
    {/* <Field>{split[1]}</Field> */}
    <Field>{item.session}</Field>
    {/* <Field>{split[3]}</Field> */}
    <Field>{item.channel}</Field>
    <Field>{item.value}</Field>
    <Field>{item.streamId}</Field>
    </>
  )
}



const renderCounters = (counters) => {
  return <div >{counters
    //.filter(c => !ids || ids.indexOf(c.typeId) > -1)
    .map(counter => (renderCounter(counter)))}</div>
}

const renderChannelCounters = (counters) => {
  //we're leaving 12 (sampled out just because of split)
  //6,7, seems to be channel info
  return <div style={{display: "grid", gridTemplateColumns: "2% 2% 5% 8% 30% auto 5%" }}>
    <Field style={{borderLeft: "1px solid", borderTop: "1px solid"}}>cid</Field>
    <Field>ct</Field>
    <Field>type</Field>
    {/* <Field>?</Field> */}
    <Field>session</Field>
    {/* <Field>?</Field> */}
    <Field>channel</Field>
    <Field>counter.value</Field>
    <Field>Stream Id</Field>
    
    {counters
      .filter(c => [1, 2, 3, 4, 5, 9, 13].indexOf(c.typeId) > -1)
      .map(c => mapChannelFromCounter(c))
      .map(item => (renderChannelDetails(item)))}</div>

}

const renderSubscriptions = (counters, udpOnly) => {
  const mapped = counters
    .filter(c => [1, 2, 3, 4, 5, 9].indexOf(c.typeId) > -1)
    .map(c => mapChannelFromCounter(c))
  const grouped = groupBy(mapped, (item) => item.session)

  const subscriptions = Object.keys(grouped)
    .filter(k => grouped[k].findIndex(i => i.typeId === 4) > -1)
    .filter(k => !udpOnly || grouped[k][0].channel.indexOf('udp') !== -1)

  subscriptions.forEach(s => console.log(`sub ${s}`, grouped[s]))

  return (<div style={{display: "grid", gridTemplateColumns: "auto auto auto auto auto auto auto" }}>
    <Field>Session</Field>
    <Field>Channel</Field>
    <Field>Receive Pos</Field>
    <Field>Sub Position</Field>
    <Field>Sub Buffer</Field>
    <Field>Receive HWM</Field>
    <Field>Hwm Ahead of Receive Pos</Field>


    {subscriptions.map(session => {
      const subPos = grouped[session].find(i => i.typeId && (i.typeId === 4))
      const rcvHwm = grouped[session].find(i => i.typeId && i.typeId === 3)
      const rcvPos = grouped[session].find(i => i.typeId && i.typeId === 5)

      return (<>
      <Field>{session}</Field>
      <Field>{grouped[session][0].channel}</Field>
      <Field>{rcvPos && rcvPos.value}</Field>
      <Field>{subPos && subPos.value}</Field>
      <Field>{rcvPos && subPos && Number(rcvPos.value) - Number(subPos.value)}</Field>
      <Field>{rcvHwm && rcvHwm.value}</Field>
      <Field>{rcvPos && rcvHwm && Number(rcvHwm.value) - Number(rcvPos.value)}</Field>
      </>)
    })}

  </div>)
}

const renderPublications = (counters, udpOnly) => {
  const mapped = counters
    .filter(c => [1, 2, 3, 4, 5, 9, 12, 13].indexOf(c.typeId) > -1)
    .map(c => mapChannelFromCounter(c))
  const grouped = groupBy(mapped, (item) => item.session)
  const publications = Object.keys(grouped)
    .filter(k => grouped[k].findIndex(i => i.typeId === 1) > -1)
    .filter(k => !udpOnly || grouped[k][0].channel.indexOf('udp') !== -1)

  publications.forEach(s => console.log(`pub ${s}`, grouped[s]))

  return (<div style={{display: "grid", gridTemplateColumns: "auto auto auto auto auto auto auto auto auto" }}>
    <Field>Session</Field>
    <Field>Channel</Field>
    <Field>Send Position</Field>
    <Field>Publication Position</Field>
    <Field>Send Buffer</Field>
    <Field>Publication Buffer</Field>
    <Field>Pub Limit</Field>
    <Field>Send Limit</Field>
    <Field>Send BPE</Field>

    {publications.map(session => {
      const pubLimit = grouped[session].find(i => i.typeId && (i.typeId === 1))
      const pubPos = grouped[session].find(i => i.typeId && i.typeId === 12)
      const sendPos = grouped[session].find(i => i.typeId && i.typeId === 2)
      const sendLimit = grouped[session].find(i => i.typeId && i.typeId === 9)
      const sendBpe = grouped[session].find(i => i.typeId && i.typeId === 13)

      return (<>
      <Field>{session}</Field>
      <Field>{grouped[session][0].channel}</Field>
      <Field>{sendPos && sendPos.value}</Field>
      <Field>{pubPos && pubPos.value}</Field>
      <Field>{sendPos && sendLimit && Number(sendLimit.value) - Number(sendPos.value)}</Field>
      <Field>{pubPos && pubLimit && Number(pubLimit.value) - Number(pubPos.value)}</Field>
      <Field>{pubLimit && pubLimit.value}</Field>
      <Field>{sendLimit && sendLimit.value}</Field>
      <Field>{sendBpe && sendBpe.value}</Field>
      </>)
    })}

  </div>)
}

export default function Member(props) {
  const { state } = props
  const [udpOnly, setUdpOnly] = useState(true)

  return (
    <div>
      <div><label>Udp only</label><input type="checkbox" checked={udpOnly} onChange={(e) => setUdpOnly(!udpOnly)}/></div>
      <div><h3>Publications</h3>{renderPublications(state.data.counters, udpOnly)}</div>
      <div><h3>Subscriptions</h3>{renderSubscriptions(state.data.counters, udpOnly)}</div>
      <div><h3>Details</h3>{renderChannelCounters(state.data.counters)}</div>
    </div>
  )
  //return renderCounters(state.data.counters.filter(c => c.counterId > 80))
}
