import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

class PrivateRoute extends Component {
  render() {
    const { component: Home, auth } = this.props
    return (
      <Route
        render={_props =>
          (auth.isAuthenticated() && !auth.hasExpired()) ? (
            <Home {...this.props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          )
        }
      />
    )
  }
}

export default PrivateRoute
