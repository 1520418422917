import React, {useState} from 'react'
import {getLiveReconRequest, postLiveReconRequest, putLiveReconRequest} from './requests'
import {toast} from "react-toastify";

export default function LiveReconClient(props) {
  const [exchangeReadOnlyFromLiveRecon, setExchangeReadOnlyUsingLiveRecon] = useState(null)

  return (
    <div>
      <h3>Live Recon Client</h3>
      <b>Exchange Configured Read only Configs Values</b>
      <div>
        <button onClick={() => getLiveReconRequestAndSet('%2Fmode%2Freadonly', setExchangeReadOnlyUsingLiveRecon)}>
          Fetch
        </button>
        <div>Configs: {JSON.stringify(exchangeReadOnlyFromLiveRecon)}</div>
      </div>
      <br/>
      <b>Exchange Configured Read only status</b>
      <div>
        <button onClick={() => toggleExchangeReadOnlyUsingLiveRecon(true)}>
          Enable Exchange ReadOnly
        </button>
        <button onClick={() => toggleExchangeReadOnlyUsingLiveRecon(false)}>
          Disable Exchange ReadOnly
        </button>
      </div>
      <br/>
      <b>Exchange Configured GoIntoReadOnlyModeOnResume</b>
      <div>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeUsingLiveRecon(true)}>
          Enable Exchange GoIntoReadOnlyModeOnResume
        </button>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeUsingLiveRecon(false)}>
          Disable Exchange GoIntoReadOnlyModeOnResume
        </button>
      </div>
      <br/>

      <button onClick={() => postLiveReconRequest('%2Frequest-checksums-from-exchange', {})}>Request checksums from exchange</button>
      <button onClick={() => postLiveReconRequest('%2Fbroadcast-local-state-to-handover-clients', {})}>Broadcast local state to handover clients</button>
      <button onClick={() => {
        const channel = window.prompt('Please provide channel:');
        postLiveReconRequest('%2Freplay-latest-broadcast-snapshot', {channel})
      }}>Replay latest broadcast snapshot
      </button>
      <button onClick={() => {
        const channel = window.prompt('Please provide channel:');
        const id = window.prompt('please provide id');
        postLiveReconRequest('%2Freplay-persisted-snapshot-from-db', {channel, id})
      }}>Replay persisted snapshot from db
      </button>
    </div>
  )
}

const getLiveReconRequestAndSet = async (url, set) => {
  set(null)
  const result = await getLiveReconRequest(url)
  set(result)
}

const toggleExchangeReadOnlyUsingLiveRecon = async enabled => {
  const result = await putLiveReconRequest('%2Fmode%2Freadonly', {value: enabled})
  console.log(result)
  if (!!result && !result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeGoIntoReadOnlyModeOnResumeUsingLiveRecon = async enabled => {
  const result = await putLiveReconRequest('%2Fmode%2Freadonly-on-resume', {value: enabled})
  if (!!result && !result.error) toast.success('Success. Please fetch again')
}
