import React, { useState } from 'react'
import { toast } from 'react-toastify'
import getInstance from 'helpers/axios'
import {
  Container, ExchangeStates, Table, TableBody,
  TableRow, TableCell, TableHeader,
} from './styles'

const members = [
  { id: 0 },
  { id: 1 },
  { id: 2 },
]

const getAllExchangeStates = async (current, set, currencyPair) => {
  const states = []
  for (const m of members) {
    const state = await exchangeStateRequest(m.id, currencyPair, current, set)
    states.push(state)
  }
  set({ ...current, states })
}

const exchangeStateRequest = async (memberId, currencyPair) => {
  try {
    const result = await getInstance().get(`/api/exchange/${memberId}/${`%2Fdata%2Forderbook%2F${currencyPair}%2Ffull`}`)
    return { ...result.data, memberId }
  } catch (e) {
    toast.error(`Could not get exchange member status ${memberId}`)
  }
}

const OrderBooks = () => {
  const [exchangeState, setExchangeState] = useState({ states: [] })
  const [checked, setChecked] = useState(false)
  const [addCurrencyPair, setCurrencyPair] = useState('')

  const handleCurrencyPairChange = (e) => setCurrencyPair(e.target.value)

  const getExchangeState = async (memberId, current, set) => {
    const data = await exchangeStateRequest(memberId, addCurrencyPair)
    const newStates = [...current.states]
    newStates[memberId] = data
    set({ ...current, states: newStates })
  }

  const differentAsks = (index, total, memberId) => {
    const { states } = exchangeState
    const differentAsksValues = []
    const otherExchange = states.filter(exchange => exchange !== undefined ? exchange.memberId !== memberId : "")
    otherExchange.forEach(exchangeData => {
      const { data } = exchangeData
      const rowArray = data.Asks
      const rowData = rowArray[index]
      if (rowData.quantity !== total) {
        differentAsksValues.push(rowData)
      }
    })
    return differentAsksValues
  }

  const differentBids = (index, total, memberId) => {
    const { states } = exchangeState
    const differentBidsValues = []
    const otherExchange = states.filter(exchange => exchange !== undefined ? exchange.memberId !== memberId : "")
    otherExchange.forEach(exchangeData => {
      const { data } = exchangeData
      const rowArray = data.Bids
      const rowData = rowArray[index]
      if (rowData.quantity !== total) {
        differentBidsValues.push(rowData)
      }
    })
    return differentBidsValues
  }

  const differentAsksColor = (index, total, memberId) => {
    const { states } = exchangeState
    const otherExchange = states.filter(exchange => exchange !== undefined ? exchange.memberId !== memberId : "")
    let colorValue = 0
    otherExchange.forEach(exchangeData => {
      const { data } = exchangeData
      const rowArray = data.Asks
      const rowData = rowArray[index]
      if (rowData.quantity !== total) {
        colorValue += 1
      }
    })
    return colorValue
  }

  const differentBidsColor = (index, total, memberId) => {
    const { states } = exchangeState
    const otherExchange = states.filter(exchange => exchange !== undefined ? exchange.memberId !== memberId : "")
    let colorValue = 0
    otherExchange.forEach(exchangeData => {
      const { data } = exchangeData
      const rowArray = data.Bids
      const rowData = rowArray[index]
      if (rowData.quantity !== total) {
        colorValue += 1
      }
    })
    return colorValue
  }

  const showAllOrders = (data) => {
    const allData = Object.entries(data.data)
    return allData.map((res) => {
      const flattenedArray = res.flat()
      if (flattenedArray[0] === 'Asks') {
        const removeFirstIndex = flattenedArray.slice(1)
        return removeFirstIndex.map((asks, i) => {
          const {
            side, quantity, price, currencyPair,
            positionAtPrice, accountId,
          } = asks
          return (
            <div key={i}>
              <TableRow style={{ color: differentAsksColor(i, quantity, data.memberId) === 0 ? '#000000' : 'red' }} >
                <TableCell flex={0.2} > {accountId} </TableCell>
                <TableCell flex={0.2} > {side} </TableCell>
                <TableCell flex={0.6} > {quantity} </TableCell>
                <TableCell flex={0.4} > {price} </TableCell>
                <TableCell flex={0.5} > {currencyPair} </TableCell>
                <TableCell flex={0.6} > {positionAtPrice} </TableCell>
              </TableRow>
            </div >
          )
        })
      } if (flattenedArray[0] === 'Bids') {
        const removeFirstIndex = flattenedArray.slice(1)
        return removeFirstIndex.map((bids, i) => {
          const {
            side, quantity, price, currencyPair, positionAtPrice, accountId,
          } = bids

          return (
            <TableRow style={{ color: differentBidsColor(i, quantity, data.memberId) === 0 ? '#000000' : 'red' }}>
              <TableCell flex={0.2} > {accountId} </TableCell>
              <TableCell flex={0.2} > {side} </TableCell>
              <TableCell flex={0.6} > {quantity} </TableCell>
              <TableCell flex={0.4} > {price} </TableCell>
              <TableCell flex={0.5} > {currencyPair} </TableCell>
              <TableCell flex={0.6} > {positionAtPrice} </TableCell>
            </TableRow>
          )
        })
      }
    })
  }

  const showMismatchingData = (data) => {
    const allData = Object.entries(data.data)
    return allData.map((res) => {
      const flattenedArray = res.flat()
      if (flattenedArray[0] === 'Asks') {
        const removeFirstIndex = flattenedArray.slice(1)
        return removeFirstIndex.map((asks, i) => {
          const {
            side, quantity, price, currencyPair, positionAtPrice, accountId,
          } = asks
          const differentASksRes = differentAsks(i, quantity, data.memberId)
          if (differentASksRes.length > 0) {
            return (
              <div>
                <TableRow style={{ color: 'red' }}>
                  <TableCell flex={0.2} > {accountId} </TableCell>
                  <TableCell flex={0.2} > {side} </TableCell>
                  <TableCell flex={0.6} > {quantity} </TableCell>
                  <TableCell flex={0.4} > {price} </TableCell>
                  <TableCell flex={0.5} > {currencyPair} </TableCell>
                  <TableCell flex={0.6} > {positionAtPrice} </TableCell>
                </TableRow>
              </div>
            )
          }
        })
      } if (flattenedArray[0] === 'Bids') {
        const removeFirstIndex = flattenedArray.slice(1)
        return removeFirstIndex.map((bids, i) => {
          const {
            side, quantity, price, currencyPair, positionAtPrice, accountId,
          } = bids
          const differentBidsRes = differentBids(i, quantity, data.memberId)
          if (differentBidsRes.length > 0) {
            return (
              <div>
                <TableRow key={i} style={{ color: 'red' }}>
                  <TableCell flex={0.2} > {accountId} </TableCell>
                  <TableCell flex={0.2} > {side} </TableCell>
                  <TableCell flex={0.6} > {quantity} </TableCell>
                  <TableCell flex={0.4} > {price} </TableCell>
                  <TableCell flex={0.5} > {currencyPair} </TableCell>
                  <TableCell flex={0.6} > {positionAtPrice} </TableCell>
                </TableRow>
              </div>
            )
          }
        })
      }
    })
  }

  const handleChange = () => {
    setChecked(!checked)
  }

  return (<Container>
    <div>
      <label>Select currency pair:
        <select
          onChange={handleCurrencyPairChange}
        >
          <option defaultValue value="">Currency Pairs</option>
          <option value="BTCZAR">BTCZAR</option>
          <option value="ETHZAR">ETHZAR</option>
          <option value="XRPZAR">XRPZAR</option>
          <option value="SOLZAR">SOLZAR</option>
          <option value="USDCZAR">USDCZAR</option>
        </select>
      </label>
      <div>
        <button disabled={addCurrencyPair === ''} onClick={() => getAllExchangeStates(exchangeState, setExchangeState, addCurrencyPair)}>Get all</button>
        {members.map(m => (<button disabled={addCurrencyPair === ''} key={m.id} onClick={() => getExchangeState(m.id, exchangeState, setExchangeState)}>Get {m.id}</button>))}
      </div>

      <label>
        <input
          style={{ fontWeight: '20px', width: '25px', height: '20px' }}
          onClick={handleChange}
          type="checkbox"
          checked={checked}
          disabled={exchangeState.states.length === 0}
        />
        show only mismatches
      </label>

      <ExchangeStates>
        {exchangeState.states.map(state => state ? (
          <div key={state.memberId}>
            <h2 style={state.role === 'LEADER' ? { fontWeight: 'bold', color: 'green' } : {}}>Exchange Member: {state.memberId} {
            }</h2>
            <div>Role: {state.role} </div>
            <Table>
              <TableHeader>
                <TableCell flex={0.2}> id </TableCell>
                <TableCell flex={0.2}> Side </TableCell>
                <TableCell flex={0.6}> Quantity </TableCell>
                <TableCell flex={0.4}> Price </TableCell>
                <TableCell flex={0.5}> Currency Pair </TableCell>
                <TableCell flex={0.6}> Position At Price </TableCell>
              </TableHeader>
              <TableBody>
                {checked ? showMismatchingData(state) :
                  showAllOrders(state)}
              </TableBody>
            </Table>

          </div>
        ) : <div>No State</div>)}
      </ExchangeStates>

    </div>

  </Container>)
}

export default OrderBooks
