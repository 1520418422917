import styled from 'styled-components'

export const Main = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

export const Content = styled.div`
  width: 80%;
  flex: 1;
  padding: 40px;
  overflow: auto;
`
export const DualAuthRawData = styled.div`
  margin: 0.1em;
  padding: 0.3em;
  flex: 1;
  flex-direction: row;
`

export const Flexbox = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  flex: ${({ flex = 'initial' }) => flex};
  justify-content: ${({ justify = 'center' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  width: ${({ width }) => `${width}`};
  margin-right: ${({ marginRight = 0 }) => `${marginRight}px`};
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
  padding: ${({ padding = 0 }) => padding};
`

export const FlexRow = styled.div`
  display: flex;
  font-weight: ${({ fontWeight }) => fontWeight};
  flex-direction: row;
  flex-wrap: wrap;
  width: ${({ width }) => `${width}`};
  justify-content: ${({ justify = 'center' }) => justify};
  margin-right: ${({ marginRight = '4px' }) => `${marginRight}px`};
  margin-bottom: ${({ marginBottom = 0 }) => `${marginBottom}px`};
  margin-top: ${({ marginTop = 0 }) => `${marginTop}px`};
`
export const FullscreenCentered = Flexbox.extend`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${({ bgColor = 'transparent' }) => bgColor};
`
export const FlexboxColumn = Flexbox.extend`
  flex-direction: column;
`

export const FlexboxRow = Flexbox.extend`
  flex-direction: row;
`
