import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
`
export const BASE = 8
export const RED = '#e42013'
export const TRANSPARENT_PINK = 'rgba(228, 32, 19, 0.1)'
export const ORANGE = '#ffbb3a'
export const GREEN = '#7CB246'
export const LIGHT_GREEN = '#e3e9dd'
export const LIGHT_BLUE = '#36b3eb'
export const BLUE = '#4569eb'
export const DARK_BLUE = '#3550b2'
export const VIOLET = '#6846c3'
export const WHITE = '#FFF'
export const GRAY_0 = '#F9F9F9'
export const GRAY_1 = '#dee1e3'
export const GRAY_2 = '#B9BBBD'
export const GRAY_3 = '#6e6e6e'
export const BLUE_GRAY = '#e4ebef'
export const BLACK = '#444444'

export const PAGINATION_ACTIVE = BLUE
export const PAGINATION_NORMAL = GRAY_2

export const WARNING_COLOR = ORANGE
export const ERROR_COLOR = RED
export const INPUT_LABEL_COLOR = GRAY_3
export const TABLE_HEADER = GRAY_2
export const BORDER_COLOR = GRAY_2
export const LIGHT_GREY = GRAY_1
export const LIGHTEST_GREY = GRAY_0
export const DARK_GREY = GRAY_3
export const DROPDOWN_ARROW_BG_COLOR = GRAY_1
export const PANEL_BLUE = BLUE
export const TIMELINE_COLOR = GRAY_1

export const DEFAULT_THEME = {
  color: {
    even: BLUE_GRAY,
    odd: GRAY_2,
    text: PANEL_BLUE,
    hover: '#c4deed',
    headerBg: '#bfc9d4',
  },
}

const blinkOnce = ({ index = 0 }) => keyframes`
  0% {
      background-color: 'green';
  }
  100% {
      background-color: ${index % 2 === 0 ? '#dce7ea' : '#eaf0f2'};
  }
`
export const ExchangeStates = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ noMargin = false }) => (noMargin ? 0 : `${BASE * 2}px`)};
  width: 100%;
`
export const TableHeader = styled.div`
  display: flex;
  width: 94%;
  background-color: ${({ colorTheme = DEFAULT_THEME }) =>
    colorTheme.color.headerBg};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  margin-bottom: 2px;
`
export const TableBody = styled.div``

export const TableCell = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: ${({ flex = '0 0 auto' }) => flex};
  padding-right: ${({ columnRender = false }) =>
    columnRender ? 0 : `${BASE * 2}px`};
  width: ${({ width = '100%' }) => width};
  max-width: ${({ maxWidth = 'auto' }) => maxWidth};
  cursor: inherit;
  font-weight: ${({ bold = false }) => (bold ? '600' : '400')};
  color: ${({ color }) => color};
  justify-content: ${({ align = 'left' }) =>
    align === 'left' ? 'flex-start' : 'flex-end'};
  text-align: ${({ align = 'left' }) => (align === 'left' ? 'left' : 'right')};
  &:last-child {
    padding-right: 0;
  }
  cursor: inherit;
`

export const TableRow = styled.div`
  display: flex;
  width: ${({ width = '94%' }) => width};
  padding: ${2 * BASE}px;
  box-sizing: border-box;
  text-decoration: none;
  align-items: center;
  color: 'black';
  background-color: ${({
    colorTheme = DEFAULT_THEME,
    index = 0,
    active = false,
  }) =>
    active
      ? `${colorTheme.color.hover} !important`
      : index % 2 === 0
        ? colorTheme.color.even
        : colorTheme.color.odd};
  margin-bottom: ${({ expanded = false, imported = false }) =>
    expanded && !imported ? 0 : '2px'};
  margin-top: ${({ expanded = false }) => (expanded ? `${BASE * 2 - 2}px` : 0)};
  cursor: pointer;
  &:nth-child(2n) {
    background-color: #dce7ea;
    animation: ${({ isNewItem = false }) =>
      isNewItem && `${'blinkOnceEven'} 1s linear 0s 1`};
  }
  &:hover {
    background-color: ${({ colorTheme = DEFAULT_THEME }) =>
      colorTheme.color.hover};
  }
  animation: ${({ isNewItem = false, index = 0 }) =>
    isNewItem && `${blinkOnce(index)} 1s linear 0s 1`};
  
  &:highlight {
    background-color: 'red'
  }
`