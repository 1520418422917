import React, { useState } from 'react'

const li = (text) => {
  return <li style={{ display: "list-item" }}>{text}</li>
}

export default function LiveReconClient() {
  const [show, setShow] = useState({ states: [] })

  if (!show) {
    return (<button onClick={() => setShow(!show)}>Show deploy steps</button>)
  }

  return (
    <div style={{width: "50%"}}>
      <h3>Glossary</h3><button onClick={() => setShow(!show)}>Hide</button>
      <ol>
        {li('Shutdown Node: This will shutdown aeron cluster (suspend and broadcast if leader), and then exitProcess')}
        {li('Shutdown Node for handover but keep node alive: This will shutdown aeron cluster (suspend and broadcast if leader), but will not exitProcess and will not close media driver, so that we can re-broadcast state if needed')}
        {li('Retry last handover: This will check if the current state equals last handover received (broadcasted from other member or live recon) and is 1 leadership term more and if true, will resume cluster')}
        {li('Retry last handover ignoring leadership term id: Same as "Retry last handover" but will ignore leadership term id comparison, will resume cluster')}
        {li('Broadcast handover if suspended: This will broadcast the current state to all handover clients configured if the cluster is suspended')}
        {li('Broadcast handover if not suspended: This will broadcast the current state to all handover clients configured even if the cluster is not suspended. If the cluster is not suspended, the state is probably changing so context should be considered here and should only be used if last resort and understood.')}
      </ol>
    </div >
  )
}
