import axios from 'axios'
import { toast } from 'react-toastify'

export const FETCH_POD_LOGS_PROCESS = 'FETCH_POD_LOGS_PROCESS'
export const FETCH_POD_LOGS_SUCCESS = 'FETCH_POD_LOGS_SUCCESS'
export const FETCH_POD_LOGS_ERROR = 'FETCH_POD_LOGS_ERROR'
const config = {
  serverUrl: 'http://localhost:3008',
}

export const getLogs = filter => async dispatch => {
  dispatch({
    type: FETCH_POD_LOGS_PROCESS,
  })
  try {
    const res = await axios.post(`${config.serverUrl}/api/monitor/pod-logs`, {
      filter: `${filter}`,
    })
    dispatch({
      type: FETCH_POD_LOGS_SUCCESS,
      payload: { data: res.data },
    })
  } catch (error) {
    dispatch({
      type: FETCH_POD_LOGS_ERROR,
      payload: { error: error.response },
    })
    toast(`Cannot get logs - ${error}`, {
      type: toast.TYPE.ERROR,
      autoClose: 2000,
    })
  }
}

// eslint-disable-next-line consistent-return
export const saveNewFilter = filter => async () => {
  try {
    const res = await axios.post(
      `${config.serverUrl}/api/monitor/save-filter`,
      filter,
    )
    return res
  } catch (error) {
    console.log('error', error)
  }
}
