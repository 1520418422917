/* eslint-disable no-alert */
import { toast } from 'react-toastify';
import getInstance from 'helpers/axios'
import config from '../../config'


const includeStandbyNodes = config.standbyCluster.includeStandbyNodes
export let members = []
if (includeStandbyNodes) {
  members =[
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 }, // standby
    { id: 4 }, // standby cluster member 0
    { id: 5 }, // standby cluster member 1
    { id: 6 }, // standby cluster member 2
  ]
} else {
  members = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 }, // standby
    { id: 4 }, // backup
  ]
}

export const getAllExchangeStates = async (current, set) => {

   const promises = members.map(m => {
     return new Promise(async (resolve) => {
       const state = await exchangeStateRequest(m.id, current, set)
       resolve(state)
     })
   })

  const states = await Promise.all([...promises])

  set({ ...current, states })
}

const exchangeStateRequest = async (memberId) => {
  try {
    const result = await getInstance().get(`/api/exchange/${memberId}/${'%2Fdata%2Fstate'}`)
    const data = { ...result.data, memberId }
    if (data.role == "BACKUP") return data
    const standbyState = data.data.counters.find(c => c.typeId == 223)
    if (standbyState != null)
      data.standbyState = standbyState.value
    return data
  } catch (e) {
    toast.error(`Could not get exchange member status ${memberId}`)
    return { memberId, error: e.message }
  }
}

export const getExchangeState = async (memberId, current, set) => {
  const data = await exchangeStateRequest(memberId)
  const newStates = [...current.states]

  newStates[memberId] = data

  set({ ...current, states: newStates })
}


export const startupPrompt = async (memberId, type, response) => {
  try {
    const res = await getInstance().post(`/api/exchange/${memberId}/%2Fstartup%2Fprompts`, {
      type,
      response,
    })
    toast.success(`Startup prompt success \n ${res.data}`)
  } catch (e) {
    toast.error(`Startup prompt fail ${e}`, {
      position: 'top-right',
      autoClose: 5000,
    })
  }
}

export const putLiveReconRequest = async (url, data) => {
  try {
    const res = await getInstance().put(`/api/liverecon/${url}`, data)
    toast.success(`Put to ${url} success: ${res.data}`)
  } catch (e) {
    toast.error(`Put fail ${e}`, {
      position: 'top-right',
      autoClose: 5000,
    })
  }
}

export const postLiveReconRequest = async (url, data) => {
  try {
    const res = await getInstance().post(`/api/liverecon/${url}`, data)
    toast.success(`Post to ${url} success: ${res.data}`)
  } catch (e) {
    toast.error(`Post fail ${e}`, {
      position: 'top-right',
      autoClose: 5000,
    })
  }
}

export const postRequest = async (memberId, url, data) => {
  try {
    const res = await getInstance().post(`/api/exchange/${memberId}/${url}`, data)
    toast.success(`Post to ${url} success: ${res.data}`)
  } catch (e) {
    toast.error(`Post fail ${e}`, {
      position: 'top-right',
      autoClose: 5000,
    })
  }
}

export const getRequest = async (memberId, url, accept = "") => {
  try {
    const result = await getInstance().get(`/api/exchange/${memberId}/${url}`)
    return { ...result.data, memberId }
  } catch (e) {
    toast.error(`Could not ${url} for ${memberId}`)
    return { memberId, error: e.message }
  }
}

export const getLiveReconRequest = async (url, data) => {
  const fullUrl = `/api/liverecon/${url}`
  try {
    const result = await getInstance().get(fullUrl)
    return result.data
  } catch (e) {
    toast.error(`Could not get ${fullUrl}. ${e.message}`)
    return { error: e.message }
  }
}

export const getSettingsRequest = async (url) => {
  const fullUrl = `/api/settings/${url}`
  try {
    const result = await getInstance().get(fullUrl)
    return result.data
  } catch (e) {
    toast.error(`Could not get ${fullUrl}. ${e.message}`)
    return { error: e.message }
  }
}

export const postSettingsRequest = async (url, data) => {
  const fullUrl = `/api/settings/${url}`
  try {
    const result = await getInstance().post(fullUrl, data)
    return result.data
  } catch (e) {
    const response = e.response
    console.log(response)
    toast.error(`Could not post ${fullUrl}. ${JSON.stringify(response.data)}`)
    return { error: response.data }
  }
}

export const snapshotRequest = async (memberId) => {
  try {
    const res = await getInstance().post(`/api/exchange/${memberId}/${'%2Fcluster%2Fsnapshot'}`)
    toast.success(`Snapshot success \n ${res.data}`)
  } catch (e) {
    toast.error(`Snapshot fail ${e}`, {
      position: 'top-right',
      autoClose: 5000,
    })
  }
}

export const urlRequest = async (memberId, url, body = {}) => {
  try {
    if (window.confirm(url.indexOf('suspend') > -1 ? 'This will most likely timeout clients but only after resume, are you sure you want to suspend reading all ingress into cluster?' : 'You sure?')) {
      const res = await getInstance().post(`/api/exchange/${memberId}/${url}`, body)
      toast.success(`Url request ${url} success \n ${res.data}`)
    } else {
      toast.error('Cancelled')
    }
  } catch (e) {
    toast.error(`Url request ${url} fail ${e}`, {
      position: 'top-right',
      autoClose: 5000,
    })
  }
}
