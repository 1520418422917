import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { pure } from 'recompose'
import { BASE } from 'theme/base-units'
import { BLUE, PANEL_BLUE } from 'theme/colors'
import { LOGO } from 'theme/images'

export const FontSize = styled.div`
  font-size: ${({ fontSize = 16 }) => `${fontSize}px`};
`

export const spinningKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const SimpleContainer = styled.div`
  color: ${({ color = BLUE }) => color};
  font-size: ${({ fontSize = BASE }) => `${fontSize}px`};
  padding: ${({ padding = 0 }) => padding};
  margin: ${({ margin = 0 }) => margin};
  margin-right: ${({ marginRight = 0 }) => marginRight};
  margin-bottom: ${({ marginBottom = 0 }) => marginBottom};

  display: ${({ display = 'block' }) => display};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'center' }) => justifyContent};

  animation: ${({ spinning }) =>
    spinning && `${spinningKeyframes} ${spinning}s linear 0s infinite`};
`

export const BlankLink = styled(Link)`
  text-decoration: none;
  color: ${({ color }) => color || 'unset'};
`

export const BlankNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ color }) => color || 'unset'};
`

export const LinkIcon = styled.i`
  margin-right: ${BASE}px;
`

export const LinkWithIcon = ({ to }) => (
  <BlankLink to={to} color={PANEL_BLUE}>
    <LinkIcon className="fas fa-link" />
    <span>Show Transactions</span>
  </BlankLink>
)

const LogoContainer = styled.div`
  padding: ${BASE * 5}px;
`
const LogoImage = styled.img`
  width: ${BASE * 20}px;
  height: auto;
`

export const Logo = pure(({ to = './' }) => (
  <LogoContainer>
    <NavLink to={to}>
      <LogoImage src={LOGO} />
    </NavLink>
  </LogoContainer>
))

export const LogoEmpty = pure(() => (
  <LogoContainer>
    <LogoImage src={LOGO} />
  </LogoContainer>
))

export const Padding = styled.div`
  display: block;
  height: ${({ value = BASE }) => `${value}px`};
`

export const FontColor = styled.div`
  color: ${({ color = 'inherit' }) => color};
`

export const ContentContainer = styled.div`
  padding: ${BASE * 3}px;
  border-top: 4px solid ${PANEL_BLUE};
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
`

export const Span = styled.span`
  color: ${({ color }) => color};
`

const StyledHeader = styled.h1`
  color: white;
  background-color: ${PANEL_BLUE};
  margin-top: 2.5%;
  width: 100%;
  border: 6px solid ${PANEL_BLUE};
`

export const Header = props => {
  const { title } = props
  return <StyledHeader>{title}</StyledHeader>
}

export const ShowStatus = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${BASE * 2.5}px;
  font-weight: bold;
  color: black;
  width: 100%;
`
