import React from 'react'
import {
  InputLabel,
  InputArea,
  Button,
  InputGroup,
  Input,
} from 'components/atoms/Form/index'

import { reduxForm } from 'redux-form'
import { FlexRow } from 'components/layout/index'
import { Select, Option, SaveIcon, Container } from './styles'

const Dropdown = props => {
  const { options } = props
  return (
    <Select width={'20%'} onChange={e => props.handleDropdown(e, options)}>
      {options.map(item => {
        const refined = JSON.parse(item)
        return (
          <Option key={refined.label} value={refined.value}>
            {refined.label}
          </Option>
        )
      })}
    </Select>
  )
}

const renderSaveFilter = props => (
  <Container
    style={{
      borderColor: 'grey',
      position: 'absolute',
      width: '20%',
      right: '5%',
      top: '80px',
    }}
  >
    <InputLabel>Enter Filter Name:</InputLabel>
    <Input onChange={props.handleLabelInput} />
    <FlexRow justify={'space-between'} marginTop={10}>
      <Button onClick={props.saveFilter}>Save</Button>
      <Button onClick={props.handleSaveBtn}>Cancel</Button>
    </FlexRow>
  </Container>
)

const FilterForm = props => {
  const {
    handlers: {
      handleTextInput,
      handleSaveBtn,
      handleDropdown,
      handleLabelInput,
    },
    saveFilter,
    filter,
    onSave,
    getLogs,
    options,
  } = props
  return (
    <Container style={{ margin: '0px', width: '100%' }}>
      <InputGroup style={{ marginTop: '10px' }}>
        <InputLabel>Filter</InputLabel>
        {options.status === 'done' && (
          <Dropdown
            name={'filterOptions'}
            options={options.data}
            handleDropdown={handleDropdown}
          />
        )}
        <InputArea onChange={handleTextInput} />
        <SaveIcon className="fa fa-save" onClick={handleSaveBtn} />

        {onSave === true &&
          renderSaveFilter({ saveFilter, handleSaveBtn, handleLabelInput })}
        <Button onClick={() => getLogs(filter)}>Submit</Button>
      </InputGroup>
    </Container>
  )
}

const ReduxFilterForm = reduxForm({
  form: 'FilterForm',
})(FilterForm)

export default ReduxFilterForm
