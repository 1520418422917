import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'react-router-redux'
import store, {history} from './store'
import auth from './services/Auth'
import App from './containers/App'
import './index.css'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckCircle, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

library.add(faCheckCircle)
library.add(faTimesCircle)

auth.init().then(() => {
  //the auth init is not the cleanest way of doing it but it does work
  const appRoot = document.getElementById('root')
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </Provider>,
    appRoot,
  )
})
